import {
  BEGIN_LOAD_KRAKEN_LEDGERS,
  LOAD_KRAKEN_LEDGERS_SUCCESS,
  LOAD_KRAKEN_LEDGERS_FAILURE
} from '../../../actions/actionTypes';

const initialState = {
  transactions: null,
  isLoading: true,
  error: null
};

const ledgers = (state = initialState, action) => {
  switch (action.type) {
    case BEGIN_LOAD_KRAKEN_LEDGERS:
      return Object.assign({}, state, {
        isLoading: true,
        error: null
      });
    case LOAD_KRAKEN_LEDGERS_SUCCESS:
      return Object.assign({}, state, {
        transactions: action.items,
        isLoading: false,
        error: null
      });
    case LOAD_KRAKEN_LEDGERS_FAILURE:
      return Object.assign({}, state, {
        transactions: null,
        isLoading: false,
        error: action.error
      });
    default:
      return state;
  }
};
export default ledgers;
