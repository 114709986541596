import {
  BEGIN_GET_PRICE_DATA,
  GET_PRICE_DATA_SUCCESS,
  GET_PRICE_DATA_FAILURE
} from './actionTypes';
import * as priceDataApi from '../../api/priceDataApi';

export function beginGetPriceData() {
  return { type: BEGIN_GET_PRICE_DATA };
}
export function getPriceDataSuccess(tickerPrice) {
  return { type: GET_PRICE_DATA_SUCCESS, tickerPrice };
}
export function getPriceDataFailure(error) {
  return { type: GET_PRICE_DATA_FAILURE, error };
}

export function getPriceData(base, quote, days) {
  return async function (dispatch, getState) {
    dispatch(beginGetPriceData());
    const token = getState().auth.token;
    try {
      const tickerPrice = await priceDataApi.getPriceData(
        base,
        quote,
        days,
        token
      );
      dispatch(getPriceDataSuccess(tickerPrice));
    } catch (error) {
      dispatch(getPriceDataFailure(error));
      throw error;
    }
  };
}
