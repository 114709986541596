import React, { useCallback } from 'react';
import TableRow from '@material-ui/core/TableRow';
import StyledTableCell from '../../../common/styled/StyledTableCell';
import CoinIcon from '../../../common/CoinIcon';
import ExchangeIcon from '../../../common/ExchangeIcon';

const PortfolioBalance = ({ balance }) => {
  const handleIconError = useCallback((err) => console.error(err.message), []);

  return (
    <TableRow hover role="checkbox" tabIndex={-1}>
      <StyledTableCell align="left" key="currency">
        <div className="d-flex align-items-center mr-4">
          <div className="d-40 d-flex align-items-center justify-content-center rounded-pill mr-3">
            <CoinIcon
              name={balance.currency.toLowerCase()}
              onError={handleIconError}
            />
          </div>
          <div>
            <div className="font-weight-bold">{balance.currency}</div>
          </div>
        </div>
      </StyledTableCell>

      <StyledTableCell align="right" key="balance">
        <div className="font-size-sm font-weight-bold">
          {parseFloat(balance.balance).toFixed(3)}
        </div>
      </StyledTableCell>

      <StyledTableCell align="right" key="bySource">
        <div>
          <div className="d-flex">
            {Object.keys(balance.sourceBalances).map((source, _index) => (
              <div className="d-flex" key={source}>
                <div className="d-20  mr-2">
                  <ExchangeIcon
                    name={source.toLowerCase()}
                    onError={handleIconError}
                  />
                </div>
                <div className="font-size-sm mr-3">
                  {parseFloat(balance.sourceBalances[source].balance).toFixed(
                    3
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </StyledTableCell>

      <StyledTableCell align="right" key="fiatAmount">
        <div className="font-size-sm font-weight-bold">
          {parseFloat(balance.equivalent).toFixed(1)}
        </div>
      </StyledTableCell>

      <StyledTableCell align="right" key="btcAmount">
        <div className="font-size-sm font-weight-bold">
          {parseFloat(balance.equivalentBTC).toFixed(3)}
        </div>
      </StyledTableCell>

      <StyledTableCell align="right" key="fees">
        <div className="font-size-sm font-weight-bold">
          {parseFloat(balance.fees).toFixed(3)}
        </div>
      </StyledTableCell>

      <StyledTableCell align="right" key="percentChange">
        <div>
          <div className="font-size-sm font-weight-bold">
            {balance.percentChange}
          </div>
        </div>
      </StyledTableCell>
    </TableRow>
  );
};

export default PortfolioBalance;
