import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Exchange from './Exchange';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'status', label: 'Status', minWidth: 170 },
  {
    id: 'api',
    label: 'Api Key',
    minWidth: 270,
    align: 'left'
  },
  {
    id: 'isValid',
    label: 'Valid?',
    minWidth: 170
  },
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 170
  }
];

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  container: {
    maxHeight: 440
  }
});

const ExchangeList = ({ exchanges, onDelete, actions }) => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.container}>
      <Table stickyHeader className="table table-borderless text-nowrap mb-0">
        <TableHead className="thead-dark text-capitalize font-size-sm font-weight-bold">
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {exchanges.map((exchange) => {
            return (
              <Exchange
                exchange={exchange}
                onDelete={onDelete}
                key={exchange.id}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ExchangeList.propTypes = {
  exchanges: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default ExchangeList;
