import {
  BEGIN_LOAD_EXCHANGES,
  LOAD_EXCHANGES_SUCCESS,
  LOAD_EXCHANGES_FAILURE,
  BEGIN_SAVE_EXCHANGE,
  CREATE_EXCHANGE_SUCCESS,
  UPDATE_EXCHANGE_SUCCESS,
  SAVE_EXCHANGE_FAILURE,
  BEGIN_DELETE_EXCHANGE,
  DELETE_EXCHANGE_SUCCESS,
  DELETE_EXCHANGE_FAILURE
} from './actionTypes';
import * as exchangeApi from '../../api/exchangeApi';

export function beginLoadExchanges() {
  return { type: BEGIN_LOAD_EXCHANGES };
}
export function loadExchangeSuccess(exchanges) {
  return { type: LOAD_EXCHANGES_SUCCESS, exchanges };
}
export function loadExchangeFailure(error) {
  return { type: LOAD_EXCHANGES_FAILURE, error };
}

export function beginSaveExchange() {
  return { type: BEGIN_SAVE_EXCHANGE };
}
export function createExchangeSuccess(exchange) {
  return { type: CREATE_EXCHANGE_SUCCESS, exchange };
}
export function updateExchangeSuccess(exchange) {
  return { type: UPDATE_EXCHANGE_SUCCESS, exchange };
}
export function saveExchangeFailure(error) {
  return { type: SAVE_EXCHANGE_FAILURE, error };
}

export function beginDeleteExchange() {
  return { type: BEGIN_DELETE_EXCHANGE };
}
export function deleteExchangeSuccess(exchange) {
  return { type: DELETE_EXCHANGE_SUCCESS, exchange };
}
export function deleteExchangeFailure(error) {
  return { type: DELETE_EXCHANGE_FAILURE, error };
}

export function loadExchanges() {
  return async function (dispatch, getState) {
    const token = getState().auth.token;
    dispatch(beginLoadExchanges());
    try {
      const exchanges = await exchangeApi.getExchanges(token);
      dispatch(loadExchangeSuccess(exchanges));
    } catch (error) {
      dispatch(loadExchangeFailure(error.message));
      throw error;
    }
  };
}

export function saveExchange(exchange) {
  //eslint-disable-next-line no-unused-vars
  return async function (dispatch, getState) {
    const token = getState().auth.token;
    dispatch(beginSaveExchange());
    try {
      const savedExchange = await exchangeApi.saveExchange(exchange, token);
      console.log(savedExchange);
      exchange.id
        ? dispatch(updateExchangeSuccess(savedExchange))
        : dispatch(createExchangeSuccess(savedExchange));
    } catch (error) {
      dispatch(saveExchangeFailure(error));
      throw error;
    }
  };
}

export function deleteExchange(exchange) {
  return async function (dispatch, getState) {
    const token = getState().auth.token;
    dispatch(beginDeleteExchange());
    try {
      await exchangeApi.deleteExchange(exchange.id, token);
      return dispatch(deleteExchangeSuccess(exchange));
    } catch (error) {
      dispatch(deleteExchangeFailure(error));
      throw error;
    }
  };
}
