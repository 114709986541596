import {
  BEGIN_LOAD_TRADES,
  LOAD_TRADES_SUCCESS,
  LOAD_TRADES_FAILURE,
  BEGIN_SAVE_TRADE,
  CREATE_TRADE_SUCCESS,
  UPDATE_TRADE_SUCCESS,
  SAVE_TRADE_FAILURE,
  BEGIN_DELETE_TRADE,
  DELETE_TRADE_SUCCESS,
  DELETE_TRADE_FAILURE
} from './actionTypes';

import * as tradeApi from '../../api/tradeApi';

export function beginLoadTrades() {
  return { type: BEGIN_LOAD_TRADES };
}
export function loadTradesSuccess(trades) {
  return { type: LOAD_TRADES_SUCCESS, trades };
}
export function loadTradesFailure(error) {
  return { type: LOAD_TRADES_FAILURE, error };
}

export function beginSaveTrade() {
  return { type: BEGIN_SAVE_TRADE };
}
export function createTradeSuccess(trade) {
  return { type: CREATE_TRADE_SUCCESS, trade };
}
export function updateTradeSuccess(trade) {
  return { type: UPDATE_TRADE_SUCCESS, trade };
}
export function saveTradeFailure(error) {
  return { type: SAVE_TRADE_FAILURE, error };
}

export function beginDeleteTrade() {
  return { type: BEGIN_DELETE_TRADE };
}
export function deleteTradeSuccess(trade) {
  return { type: DELETE_TRADE_SUCCESS, trade };
}
export function deleteTradeFailure(error) {
  return { type: DELETE_TRADE_FAILURE, error };
}

export function loadTrades() {
  return async function (dispatch, getState) {
    const token = getState().auth.token;
    dispatch(beginLoadTrades());
    try {
      const trades = await tradeApi.getTrades(token);
      dispatch(loadTradesSuccess(trades));
    } catch (error) {
      dispatch(loadTradesFailure(error));
      throw error;
    }
  };
}

export function saveTrade(trade) {
  //eslint-disable-next-line no-unused-vars
  return async function (dispatch, getState) {
    const token = getState().auth.token;
    dispatch(beginSaveTrade());
    try {
      const savedTrade = await tradeApi.saveTrade(trade, token);
      trade.id
        ? dispatch(updateTradeSuccess(savedTrade))
        : dispatch(createTradeSuccess(savedTrade));
    } catch (error) {
      dispatch(saveTradeFailure(error));
      throw error;
    }
  };
}

export function deleteTrade(trade) {
  return async function (dispatch, getState) {
    const token = getState().auth.token;
    dispatch(beginDeleteTrade());
    try {
      await tradeApi.deleteTrade(trade._id, token);
      return dispatch(deleteTradeSuccess(trade));
    } catch (error) {
      dispatch(deleteTradeFailure(error));
      throw error;
    }
  };
}
