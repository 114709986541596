import React from "react";

import PageError4041 from "./PageError4041";
export default function PageError404() {
  return (
    <>
      <PageError4041 />
    </>
  );
}
