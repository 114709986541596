import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TrendingDownTwoToneIcon from '@material-ui/icons/TrendingDownTwoTone';
import Chart from 'react-apexcharts';
import { roundTo } from '../../utils/Maths';

const SidebarWidget = ({ portfolio, loading }) => {
  const chart33Options = {
    chart: {
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: true
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      color: '#3c44b1',
      curve: 'smooth',
      width: 2
    },
    fill: {
      color: '#3c44b1',
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.6,
        opacityTo: 0,
        stops: [0, 100]
      }
    },
    legend: {
      show: false
    },
    xaxis: {
      categories: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ],
      crosshairs: {
        width: 0
      }
    },
    yaxis: {
      min: 0
    }
  };
  const chart33Data = [
    {
      name: 'Transactions',
      data: [47, 45, 54, 38, 56, 24, 65]
    }
  ];

  return (
    <>
      <div className="app-sidebar--widget">
        <div className="sidebar-header align-items-center font-weight-bold d-flex justify-content-between text-primary">
          <span>Watch list</span>
          <div>
            <a
              href="#/"
              onClick={(e) => e.preventDefault()}
              className="text-capitalize font-weight-normal text-first"
              title="View details">
              See all
            </a>
          </div>
        </div>
        <div className="app-sidebar-spacer">
          <div className="d-flex justify-content-between mt-2 mb-1">
            <div className="d-flex">
              {!loading && Object.keys(portfolio).length != 0 && (
                <>
                  <div className="font-size-lg text-danger">
                    <TrendingDownTwoToneIcon />
                  </div>
                  <div className="text-left ml-3">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="font-weight-bold">
                        {roundTo(portfolio.totalEquivalentBTC, 3)} BTC
                      </div>
                      <div className="badge badge-neutral-danger ml-2 text-danger">
                        {portfolio.percentChange}%
                      </div>
                    </div>
                    <div className="text-black opacity-4 font-size-sm">
                      {Math.floor(portfolio.totalEquivalent)}{' '}
                      {portfolio.equivalentCurrency}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div>
          <Chart
            options={chart33Options}
            series={chart33Data}
            type="area"
            height={80}
          />
        </div>
      </div>
    </>
  );
};

SidebarWidget.propTypes = {
  portfolio: PropTypes.object,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  portfolio: state.portfolio.portfolio,
  loading: state.portfolio.isLoading
});

export default connect(mapStateToProps, null)(SidebarWidget);
