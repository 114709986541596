import {
  BEGIN_SAVE_TRANSACTION,
  CREATE_TRANSACTION_SUCCESS,
  UPDATE_TRANSACTION_SUCCESS,
  SAVE_TRANSACTION_FAILURE,
  BEGIN_LOAD_TRANSACTIONS,
  LOAD_TRANSACTIONS_SUCCESS,
  LOAD_TRANSACTIONS_FAILURE,
  BEGIN_DELETE_TRANSACTION,
  DELETE_TRANSACTION_SUCCESS,
  DELETE_EXCHANGE_FAILURE,
  BEGIN_UPLOAD_EXCEL_FILE,
  UPLOAD_EXCEL_FILE_SUCCESS,
  UPLOAD_EXCEL_FILE_FAILURE,
  BEGIN_DOWNLOAD_EXCEL_FILE,
  DOWNLOAD_EXCEL_FILE_SUCCESS,
  DOWNLOAD_EXCEL_FILE_FAILURE
} from '../../actions/actionTypes';

const initialState = {
  transactionList: null,
  isLoading: true,
  uploadFinished: false,
  downloadFinished: false,
  success: null,
  error: null
};

export const getTransactions = (state) => state.transactionList;

const transactions = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_TRANSACTION_SUCCESS:
      return Object.assign({}, state, {
        transactionList: [...state.transactionList, { ...action.transaction }]
      });
    case UPDATE_TRANSACTION_SUCCESS:
      return Object.assign({}, state, {
        transactionList: state.transactionList.map((transaction) =>
          transaction._id === action.transaction._id
            ? action.transaction
            : transaction
        )
      });
    case BEGIN_LOAD_TRANSACTIONS:
      return Object.assign({}, state, {
        isLoading: true,
        error: null
      });
    case LOAD_TRANSACTIONS_SUCCESS:
      return Object.assign({}, state, {
        transactionList: action.transactions,
        isLoading: false,
        error: null
      });
    case LOAD_TRANSACTIONS_FAILURE:
      return Object.assign({}, state, {
        transactionList: [],
        isLoading: false,
        error: action.error
      });
    case DELETE_TRANSACTION_SUCCESS:
      return Object.assign({}, state, {
        transactionList: state.transactionList.filter(
          (transaction) => transaction._id !== action.transaction._id
        ),
        error: null
      });
    case BEGIN_UPLOAD_EXCEL_FILE:
      return Object.assign({}, state, {
        uploadFinished: false,
        success: null,
        error: null
      });
    case UPLOAD_EXCEL_FILE_SUCCESS:
      return Object.assign({}, state, {
        uploadFinished: true,
        success: action.message,
        error: null
      });
    case UPLOAD_EXCEL_FILE_FAILURE:
      return Object.assign({}, state, {
        uploadFinished: true,
        success: null,
        error: action.error
      });
    case BEGIN_DOWNLOAD_EXCEL_FILE:
      return Object.assign({}, state, {
        downloadFinished: false,
        success: null,
        error: null
      });
    case DOWNLOAD_EXCEL_FILE_SUCCESS:
      return Object.assign({}, state, {
        downloadFinished: true,
        success: 'Excel export successful.',
        error: null
      });
    case DOWNLOAD_EXCEL_FILE_FAILURE:
      return Object.assign({}, state, {
        downloadFinished: true,
        success: null,
        error: action.error
      });
    default:
      return state;
  }
};
export default transactions;
