import { createHeaders, handleResponse, handleError } from './apiUtils';

const BASE_URL = process.env.API_URL + '/exchanges/';

export async function getExchanges(authToken) {
  return fetch(BASE_URL, {
    method: 'GET',
    headers: createHeaders(authToken)
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getSupportedExchanges(authToken) {
  return fetch(BASE_URL + 'supported', {
    method: 'GET',
    headers: createHeaders(authToken)
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getExchangeById(exchangeId, authToken) {
  return fetch(BASE_URL + exchangeId, {
    method: 'GET',
    headers: createHeaders(authToken)
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function saveExchange(exchange, authToken) {
  return fetch(BASE_URL + (exchange._id || ''), {
    method: 'POST', // TODO: exchange._id ? 'PUT' : 'POST', // POST for create, PUT to update when id already exists.
    headers: createHeaders(authToken),
    body: JSON.stringify(exchange)
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function deleteExchange(exchangeId, authToken) {
  return fetch(BASE_URL + exchangeId, {
    method: 'DELETE',
    headers: createHeaders(authToken)
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getExchangeApiStatus(name, authToken) {
  var url = new URL(BASE_URL + 'status');
  var params = { name };
  url.search = new URLSearchParams(params).toString();
  return fetch(url, {
    method: 'GET',
    headers: createHeaders(authToken)
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getExchangeServerTime(name, authToken) {
  var url = new URL(BASE_URL + 'time');
  var params = { name };
  url.search = new URLSearchParams(params).toString();
  return fetch(url, {
    method: 'GET',
    headers: createHeaders(authToken)
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function testExchangeApiKey(exchangeId, authToken) {
  var url = new URL(BASE_URL + 'key');
  var params = { exchangeId };
  url.search = new URLSearchParams(params).toString();
  return fetch(url, {
    method: 'GET',
    headers: createHeaders(authToken)
  })
    .then(handleResponse)
    .catch(handleError);
}
