import { combineReducers } from 'redux';
import summary from './summaryReducer';
import trades from './tradeReducer';
import binance from './binance';
import kraken from './kraken';

export default combineReducers({
  summary,
  trades,
  binance,
  kraken
});
