import React from "react";

import PageRegisterCover1 from "./PageRegisterCover1";
export default function PageRegisterCover() {
  return (
    <>
      <PageRegisterCover1 />
    </>
  );
}
