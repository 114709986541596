import {
  BEGIN_LOAD_BINANCE_CRYPTO_TRANSACTIONS,
  LOAD_BINANCE_CRYPTO_TRANSACTIONS_SUCCESS,
  LOAD_BINANCE_CRYPTO_TRANSACTIONS_FAILURE
} from '../../../actions/actionTypes';

const initialState = {
  transactions: null,
  isLoading: true,
  error: null
};

const depositsAndWithdraws = (state = initialState, action) => {
  switch (action.type) {
    case BEGIN_LOAD_BINANCE_CRYPTO_TRANSACTIONS:
      return Object.assign({}, state, {
        isLoading: true,
        error: null
      });
    case LOAD_BINANCE_CRYPTO_TRANSACTIONS_SUCCESS:
      return Object.assign({}, state, {
        transactions: action.items,
        isLoading: false,
        error: null
      });
    case LOAD_BINANCE_CRYPTO_TRANSACTIONS_FAILURE:
      return Object.assign({}, state, {
        transactions: null,
        isLoading: false,
        error: action.error
      });
    default:
      return state;
  }
};

export default depositsAndWithdraws;
