import {
  BEGIN_SAVE_TRADE,
  CREATE_TRADE_SUCCESS,
  UPDATE_TRADE_SUCCESS,
  SAVE_TRADE_FAILURE,
  BEGIN_LOAD_TRADES,
  LOAD_TRADES_SUCCESS,
  LOAD_TRADES_FAILURE,
  BEGIN_DELETE_TRADE,
  DELETE_TRADE_SUCCESS,
  DELETE_EXCHANGE_FAILURE
} from '../../actions/actionTypes';

const initialState = {
  tradeList: null,
  isLoading: true,
  error: null
};

export const getTrades = (state) => state.tradeList;

const trades = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_TRADE_SUCCESS:
      return Object.assign({}, state, {
        tradeList: [...state.tradeList, { ...action.trade }]
      });
    case UPDATE_TRADE_SUCCESS:
      return Object.assign({}, state, {
        tradeList: state.tradeList.map((trade) =>
          trade._id === action.trade._id ? action.trade : trade
        )
      });
    case BEGIN_LOAD_TRADES:
      return Object.assign({}, state, {
        isLoading: true,
        error: null
      });
    case LOAD_TRADES_SUCCESS:
      return Object.assign({}, state, {
        tradeList: action.trades,
        isLoading: false,
        error: null
      });
    case LOAD_TRADES_FAILURE:
      return Object.assign({}, state, {
        tradeList: null,
        isLoading: false,
        error: action.error
      });
    case DELETE_TRADE_SUCCESS:
      return Object.assign({}, state, {
        tradeList: state.tradeList.filter(
          (trade) => trade._id !== action.trade._id
        ),
        error: null
      });
    default:
      return state;
  }
};
export default trades;
