import React from "react";

import PageRecoverCover1 from "./PageRecoverCover1";
export default function PageRecoverCover() {
  return (
    <>
      <PageRecoverCover1 />
    </>
  );
}
