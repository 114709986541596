import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../common/TextInput';
import SelectInput from '../common/SelectInput';
import { Button } from '@material-ui/core';

const ExchangeForm = ({
  exchange,
  exchangeVendors,
  onSave,
  onChange,
  saving = false,
  errors = {}
}) => {
  return (
    <form onSubmit={onSave}>
      <h2>{exchange._id ? 'Edit' : 'Add'} Exchange</h2>
      {errors.onSave && (
        <div className="alert alert-danger" role="alert">
          {errors.onSave}
        </div>
      )}

      <SelectInput
        name="name"
        label="Exchange"
        value={exchange.name || ''}
        options={exchangeVendors.map((exchangeVendor) => ({
          value: exchangeVendor,
          text: exchangeVendor
        }))}
        onChange={onChange}
        error={errors.name}
      />

      <TextInput
        name="api"
        label="Api Key"
        value={exchange.api}
        onChange={onChange}
        error={errors.api}
      />

      <TextInput
        name="secret"
        label="Api Secret"
        value={exchange.secret}
        onChange={onChange}
        error={errors.secret}
      />
      <Button
        type="submit"
        disabled={saving}
        size="small"
        className="py-2 px-4 btn-primary mt-4">
        <span className="btn-wrapper--label text-uppercase font-weight-bold">
          {saving ? 'Saving...' : 'Save'}
        </span>
      </Button>
    </form>
  );
};

ExchangeForm.propTypes = {
  exchangeVendors: PropTypes.array.isRequired,
  exchange: PropTypes.object.isRequired,
  errors: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  saving: PropTypes.bool
};

export default ExchangeForm;
