import React from 'react';
import CountUp from 'react-countup';

const TotalBalance = ({ balance, currency }) => {
  let roundedBalance = Math.floor(balance);

  return (
    <>
      <h3 className="display-3 mb-0 text-center font-weight-bold">
        <span className="pl-1">
          <CountUp
            start={0}
            end={roundedBalance}
            duration={4}
            separator=""
            delay={1}
            decimals={0}
            decimal="."
            prefix=""
            suffix=""
          />
        </span>
        <small className="opacity-6">{' ' + currency}</small>
      </h3>
      <small className="text-center d-block font-weight-bold text-muted text-uppercase">
        Total balance
      </small>
    </>
  );
};

export default TotalBalance;
