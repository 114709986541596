import { createHeaders, handleResponse, handleError } from './apiUtils';
const baseUrl = process.env.API_URL + '/trades/';

export async function getTrades(authToken) {
  return fetch(baseUrl, {
    method: 'GET',
    headers: createHeaders(authToken)
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getTradeById(tradeId, authToken) {
  return fetch(baseUrl + tradeId, {
    method: 'GET',
    headers: createHeaders(authToken)
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function saveTrade(trade, authToken) {
  return fetch(baseUrl + (trade._id || ''), {
    method: trade._id ? 'PUT' : 'POST', // POST for create, PUT to update when id already exists.
    headers: createHeaders(authToken),
    body: JSON.stringify(trade)
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function deleteTrade(tradeId, authToken) {
  return fetch(baseUrl + tradeId, {
    method: 'DELETE',
    headers: createHeaders(authToken)
  })
    .then(handleResponse)
    .catch(handleError);
}
