import {
  BEGIN_UPLOAD_EXCEL_FILE,
  UPLOAD_EXCEL_FILE_SUCCESS,
  UPLOAD_EXCEL_FILE_FAILURE,
  BEGIN_DOWNLOAD_EXCEL_FILE,
  DOWNLOAD_EXCEL_FILE_SUCCESS,
  DOWNLOAD_EXCEL_FILE_FAILURE
} from './actionTypes';

import * as excelApi from '../../api/excelApi';
import { saveAs } from 'file-saver';

export function beginUploadExcelFile() {
  return { type: BEGIN_UPLOAD_EXCEL_FILE };
}
export function uploadExcelFileSuccess(message) {
  return { type: UPLOAD_EXCEL_FILE_SUCCESS, message };
}
export function uploadExcelFileFailure(error) {
  return { type: UPLOAD_EXCEL_FILE_FAILURE, error };
}

export function beginDownloadExcelFile() {
  return { type: BEGIN_DOWNLOAD_EXCEL_FILE };
}
export function downloadExcelFileSuccess() {
  return { type: DOWNLOAD_EXCEL_FILE_SUCCESS };
}
export function downloadExcelFileFailure(error) {
  return { type: DOWNLOAD_EXCEL_FILE_FAILURE, error };
}

export function uploadExcelFile(file) {
  return async function (dispatch, getState) {
    const token = getState().auth.token;
    dispatch(beginUploadExcelFile());
    try {
      let result = await excelApi.uploadTransactions(file, token);
      dispatch(uploadExcelFileSuccess(result.message));
    } catch (error) {
      dispatch(uploadExcelFileFailure(error.message));
      throw error;
    }
  };
}

export function downloadExcelFile() {
  return async function (dispatch, getState) {
    const token = getState().auth.token;
    dispatch(beginDownloadExcelFile());
    try {
      let file = await excelApi.downloadTransactions(token);
      saveAs(file, 'transactions.xlsx');
      dispatch(downloadExcelFileSuccess());
    } catch (error) {
      dispatch(downloadExcelFileFailure(error.message));
      throw error;
    }
  };
}
