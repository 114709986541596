import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as exchangeActions from '../../redux/actions/exchangeActions';
import * as exchangeVendorActions from '../../redux/actions/exchangeVendorActions';
import PropTypes from 'prop-types';

import { PageTitle } from '../../layout-components';
import Card from '@material-ui/core/Card';
import ExchangeList from './ExchangeList';
import { Redirect } from 'react-router-dom';
import Spinner from '../common/Spinner';
import { toast } from 'react-toastify';
import { Button } from '@material-ui/core';
import { useEffect } from 'react';

const ExchangesPage = ({ auth, exchanges, loading, error, actions }) => {
  const [redirectToAddExchangePage, setRedirectToAddExchangePage] = useState(
    false
  );

  useEffect(() => {
    if (
      auth.isAuthenticated == true &&
      (exchanges == 'undefined' || exchanges == null)
    ) {
      loadExchanges();
    }
    //loadExchangeVendors();
  }, [auth, exchanges]);

  const loadExchanges = () => {
    console.log('Loading exchanges..');
    actions.loadExchanges().catch((error) => {
      console.log('Loading exchanges failed ' + error);
    });
  };
  /* const loadExchangeVendors = () => {
    if (props.exchangeVendors === null || props.exchangeVendors.length === 0) {
      console.log('Loading exchange vendors..');
      actions.loadExchangeVendors().catch((error) => {
        console.log('Loading supported exchanges failed' + error);
      });
    }
  }; */
  const handleDeleteExchange = async (exchange) => {
    try {
      await actions.deleteExchange(exchange);
    } catch (error) {
      toast.error('Delete failed. ' + error.message, { autoClose: false });
    }
    toast.success('Exchange deleted');
    //loadExchanges();
  };

  return (
    <>
      <PageTitle
        titleHeading="Exchanges"
        titleDescription="Manage your Api Keys to connect to exchanges."></PageTitle>
      {redirectToAddExchangePage && <Redirect to="/add-exchange" />}
      <Card className="mb-5 bg-dark">
        <div className="card-header d-flex align-items-center justify-content-between card-header-alt p-4">
          <div>
            <h6 className="font-weight-bold font-size-lg mb-0">
              Exchange Api Keys
            </h6>
          </div>
        </div>
        <div className="divider" />
        <div className="divider" />
        {loading ? (
          <Spinner />
        ) : (
          <>
            <Button
              onClick={() => setRedirectToAddExchangePage(true)}
              size="small"
              variant="text"
              className="btn-outline-primary d-flex align-items-center justify-content-center ml-4 mb-3 mt-3">
              <span className="btn-wrapper--label text-uppercase font-weight-bold">
                Add Exchange
              </span>
            </Button>

            <ExchangeList
              onDelete={handleDeleteExchange}
              exchanges={exchanges}
            />
          </>
        )}
      </Card>
    </>
  );
};

ExchangesPage.propTypes = {
  auth: PropTypes.object.isRequired,
  exchangeVendors: PropTypes.array.isRequired,
  exchanges: PropTypes.array,
  actions: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    exchanges: state.exchanges.exchangeList,
    exchangeVendors: state.exchangeVendors,
    loading: state.exchanges.isLoading,
    error: state.exchanges.error,
    actions: PropTypes.object.isRequired
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loadExchanges: bindActionCreators(
        exchangeActions.loadExchanges,
        dispatch
      ),
      loadExchangeVendors: bindActionCreators(
        exchangeVendorActions.loadExchangeVendors,
        dispatch
      ),
      deleteExchange: bindActionCreators(
        exchangeActions.deleteExchange,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExchangesPage);
