import React from 'react';
import { PageTitle } from '../../layout-components';

import PortfolioSummary from './PortfolioSummary';
export default function Portfolio() {
  return (
    <>
      <PageTitle
        titleHeading="Portfolio"
        titleDescription="This page shows your complete portfolio balance sheet, calculated from all your transactions.">
        {/* <PortfolioPageTitleActions /> */}
      </PageTitle>
      <PortfolioSummary />
    </>
  );
}
