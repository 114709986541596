import React, { useState } from 'react';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';
import { useAsyncDebounce } from 'react-table';

const TableHeaderSearch = ({
  id,
  title,
  globalFilter,
  preGlobalFilteredRows,
  setGlobalFilter
}) => {
  const [searchOpen, setSearchOpen] = useState(false);

  const openSearch = () => setSearchOpen(true);
  const closeSearch = () => setSearchOpen(false);

  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  return (
    <>
      <div className="card-header d-flex align-items-center justify-content-between card-header-alt p-4">
        <div className={clsx({ 'd-none': searchOpen })}>
          <h6 className="font-weight-bold font-size-lg mb-0">{title}</h6>
        </div>
        <div
          className={clsx('d-flex align-items-center', {
            'w-100': searchOpen
          })}>
          <div
            className={clsx('search-wrapper search-wrapper--grow w-100', {
              'is-active': searchOpen
            })}>
            <TextField
              variant="outlined"
              size="small"
              id={id}
              value={value || ''}
              placeholder={`Search ${count} records...`}
              onFocus={openSearch}
              onBlur={closeSearch}
              onChange={(e) => {
                setValue(e.target.value);
                onChange(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchTwoToneIcon />
                  </InputAdornment>
                )
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TableHeaderSearch;
