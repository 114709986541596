import React from "react";

import PageLoginCover1 from "./PageLoginCover1";
export default function PageLoginCover() {
  return (
    <>
      <PageLoginCover1 />
    </>
  );
}
