import { withStyles } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';

const StyledTableCell = withStyles({
  root: {
    color: 'white'
  }
})(TableCell);

export default StyledTableCell;
