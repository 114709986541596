import React, { useEffect } from 'react';
import { useFilePicker } from 'use-file-picker';
import { saveAs } from 'file-saver';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import Spinner from '../../../../common/Spinner';
import TemplateFile from '../../../../../assets/excel/upload.xlsx';

const UploadMenu = ({ anchorEl, handleClose, handleFileUpload }) => {
  const [openFileSelector, { plainFiles, loading, errors }] = useFilePicker({
    accept: '.xlsx',
    multiple: false,
    maxFileSize: 1,
    readAs: 'BinaryString'
  });

  useEffect(() => {
    if (plainFiles.length > 0 && loading == false) {
      handleFileUpload(plainFiles[0]);
    }
  }, [loading]);

  let button;
  if (errors.length) {
    button = (
      <div>
        <Button
          className="btn-primary"
          size="small"
          onClick={() => openFileSelector()}>
          Retry
        </Button>
        {errors[0].fileSizeToolarge &&
          'File size is too large! Max. file size is 1MB'}
        {errors[0].readerError && 'Problem occured while reading file!'}
      </div>
    );
  } else {
    button = (
      <Button
        className="btn-primary"
        size="small"
        onClick={() => openFileSelector()}>
        Choose File
      </Button>
    );
  }

  const handleSave = () => {
    saveAs(TemplateFile, 'upload.xlsx');
  };

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={Boolean(anchorEl)}
      classes={{ list: 'p-0' }}
      onClose={handleClose}>
      <div className="dropdown-menu-lg overflow-hidden p-0">
        <div className="font-weight-bold px-4 pt-3">Upload Excel file</div>
        <div className="p-3 text-center">
          {loading ? <Spinner /> : <div>{button}</div>}
        </div>
        <div className="divider" />
        <div className="font-weight-bold px-4 pt-4">Download template</div>
        <div className="p-3 text-center">
          <Button className="btn-primary" size="small" onClick={handleSave}>
            Download
          </Button>
        </div>
      </div>
    </Menu>
  );
};

export default UploadMenu;
