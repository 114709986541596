import {
  BEGIN_LOAD_BINANCE_CRYPTO_TRANSACTIONS,
  LOAD_BINANCE_CRYPTO_TRANSACTIONS_SUCCESS,
  LOAD_BINANCE_CRYPTO_TRANSACTIONS_FAILURE,
  BEGIN_LOAD_BINANCE_DISTRIBUTIONS,
  LOAD_BINANCE_DISTRIBUTIONS_SUCCESS,
  LOAD_BINANCE_DISTRIBUTIONS_FAILURE,
  BEGIN_LOAD_BINANCE_FIAT_TRANSACTIONS,
  LOAD_BINANCE_FIAT_TRANSACTIONS_SUCCESS,
  LOAD_BINANCE_FIAT_TRANSACTIONS_FAILURE,
  BEGIN_LOAD_BINANCE_FIAT_PAYMENTS,
  LOAD_BINANCE_FIAT_PAYMENTS_SUCCESS,
  LOAD_BINANCE_FIAT_PAYMENTS_FAILURE,
  BEGIN_LOAD_KRAKEN_LEDGERS,
  LOAD_KRAKEN_LEDGERS_SUCCESS,
  LOAD_KRAKEN_LEDGERS_FAILURE,
  BEGIN_LOAD_KRAKEN_STAKING,
  LOAD_KRAKEN_STAKING_SUCCESS,
  LOAD_KRAKEN_STAKING_FAILURE
} from './actionTypes';

import * as transactionApi from '../../api/transactionApi';

export function beginLoadBinanceCryptoTransactions() {
  return { type: BEGIN_LOAD_BINANCE_CRYPTO_TRANSACTIONS };
}
export function loadBinanceCryptoTransactionsSuccess(items) {
  return { type: LOAD_BINANCE_CRYPTO_TRANSACTIONS_SUCCESS, items };
}
export function loadBinanceCryptoTransactionsFailure(error) {
  return { type: LOAD_BINANCE_CRYPTO_TRANSACTIONS_FAILURE, error };
}

export function beginLoadBinanceDistributions() {
  return { type: BEGIN_LOAD_BINANCE_DISTRIBUTIONS };
}
export function loadBinanceDistributionsSuccess(items) {
  return { type: LOAD_BINANCE_DISTRIBUTIONS_SUCCESS, items };
}
export function loadBinanceDistributionsFailure(error) {
  return { type: LOAD_BINANCE_DISTRIBUTIONS_FAILURE, error };
}

export function beginLoadBinanceFiatTransactions() {
  return { type: BEGIN_LOAD_BINANCE_FIAT_TRANSACTIONS };
}
export function loadBinanceFiatTransactionsSuccess(items) {
  return { type: LOAD_BINANCE_FIAT_TRANSACTIONS_SUCCESS, items };
}
export function loadBinanceFiatTransactionsFailure(error) {
  return { type: LOAD_BINANCE_FIAT_TRANSACTIONS_FAILURE, error };
}

export function beginLoadBinanceFiatPayments() {
  return { type: BEGIN_LOAD_BINANCE_FIAT_PAYMENTS };
}
export function loadBinanceFiatPaymentsSuccess(items) {
  return { type: LOAD_BINANCE_FIAT_PAYMENTS_SUCCESS, items };
}
export function loadBinanceFiatPaymentsFailure(error) {
  return { type: LOAD_BINANCE_FIAT_PAYMENTS_FAILURE, error };
}

export function beginLoadKrakenLedgers() {
  return { type: BEGIN_LOAD_KRAKEN_LEDGERS };
}
export function loadKrakenLedgersSuccess(items) {
  return { type: LOAD_KRAKEN_LEDGERS_SUCCESS, items };
}
export function loadKrakenLedgersFailure(error) {
  return { type: LOAD_KRAKEN_LEDGERS_FAILURE, error };
}

export function beginLoadKrakenStaking() {
  return { type: BEGIN_LOAD_KRAKEN_STAKING };
}
export function loadKrakenStakingSuccess(items) {
  return { type: LOAD_KRAKEN_STAKING_SUCCESS, items };
}
export function loadKrakenStakingFailure(error) {
  return { type: LOAD_KRAKEN_STAKING_FAILURE, error };
}

export function loadBinanceCryptoTransactions() {
  return async function (dispatch, getState) {
    const token = getState().auth.token;
    dispatch(beginLoadBinanceCryptoTransactions());
    try {
      const items = await transactionApi.getBinanceDepositsAndWithdraws(token);
      dispatch(loadBinanceCryptoTransactionsSuccess(items));
    } catch (error) {
      dispatch(loadBinanceCryptoTransactionsFailure(error));
      throw error;
    }
  };
}

export function loadBinanceDistributions() {
  return async function (dispatch, getState) {
    const token = getState().auth.token;
    dispatch(beginLoadBinanceDistributions());
    try {
      const items = await transactionApi.getBinanceDistributions(token);
      dispatch(loadBinanceDistributionsSuccess(items));
    } catch (error) {
      dispatch(loadBinanceDistributionsFailure(error));
      throw error;
    }
  };
}

export function loadBinanceFiatTransactions() {
  return async function (dispatch, getState) {
    const token = getState().auth.token;
    dispatch(beginLoadBinanceFiatTransactions());
    try {
      const items = await transactionApi.getBinanceFiatTransactions(token);
      dispatch(loadBinanceFiatTransactionsSuccess(items));
    } catch (error) {
      dispatch(loadBinanceFiatTransactionsFailure(error));
      throw error;
    }
  };
}

export function loadBinanceFiatPayments() {
  return async function (dispatch, getState) {
    const token = getState().auth.token;
    dispatch(beginLoadBinanceFiatPayments());
    try {
      const items = await transactionApi.getBinanceFiatPayments(token);
      dispatch(loadBinanceFiatPaymentsSuccess(items));
    } catch (error) {
      dispatch(loadBinanceFiatPaymentsFailure(error));
      throw error;
    }
  };
}

export function loadKrakenLedgers() {
  return async function (dispatch, getState) {
    const token = getState().auth.token;
    dispatch(beginLoadKrakenLedgers());
    try {
      const items = await transactionApi.getKrakenLedgers(token);
      dispatch(loadKrakenLedgersSuccess(items));
    } catch (error) {
      dispatch(loadKrakenLedgersFailure(error));
      throw error;
    }
  };
}

export function loadKrakenStaking() {
  return async function (dispatch, getState) {
    const token = getState().auth.token;
    dispatch(beginLoadKrakenStaking());
    try {
      const items = await transactionApi.getKrakenStaking(token);
      dispatch(loadKrakenStakingSuccess(items));
    } catch (error) {
      dispatch(loadKrakenStakingFailure(error));
      throw error;
    }
  };
}
