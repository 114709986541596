import React from 'react';
import { PageTitle } from '../../layout-components';

import TransactionsTrades from './TransactionsTrades';
import TransactionsSummary from './TransactionsSummary';
export default function Transactions() {
  return (
    <>
      <PageTitle
        titleHeading="Transactions"
        titleDescription="This page shows all your ledger information, like trades, deposits, withdrawals and staking rewards.">
        {/* <TransactionsPageTitleActions /> */}
      </PageTitle>
      <TransactionsSummary />
    </>
  );
}
