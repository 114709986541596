import {
  createHeaders,
  createHeadersFileUpload,
  handleResponse,
  handleFileResponse,
  handleError
} from './apiUtils';
const baseUrl = process.env.API_URL + '/excel/';

export async function uploadTransactions(file, authToken) {
  var data = new FormData();
  data.append('file', file);

  return fetch(baseUrl + 'upload', {
    method: 'POST',
    headers: createHeadersFileUpload(authToken),
    body: data
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function downloadTransactions(authToken) {
  return fetch(baseUrl + 'download', {
    method: 'GET',
    headers: createHeaders(authToken)
  })
    .then(handleFileResponse)
    .catch(handleError);
}
