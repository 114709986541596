import {
  BEGIN_LOAD_TRANSACTIONS,
  LOAD_TRANSACTIONS_SUCCESS,
  LOAD_TRANSACTIONS_FAILURE,
  BEGIN_SAVE_TRANSACTION,
  CREATE_TRANSACTION_SUCCESS,
  UPDATE_TRANSACTION_SUCCESS,
  SAVE_TRANSACTION_FAILURE,
  BEGIN_DELETE_TRANSACTION,
  DELETE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION_FAILURE
} from './actionTypes';

import * as transactionApi from '../../api/transactionApi';

export function beginLoadTransactions() {
  return { type: BEGIN_LOAD_TRANSACTIONS };
}
export function loadTransactionsSuccess(transactions) {
  return { type: LOAD_TRANSACTIONS_SUCCESS, transactions };
}
export function loadTransactionsFailure(error) {
  return { type: LOAD_TRANSACTIONS_FAILURE, error };
}

export function beginSaveTransaction() {
  return { type: BEGIN_SAVE_TRANSACTION };
}
export function createTransactionSuccess(transaction) {
  return { type: CREATE_TRANSACTION_SUCCESS, transaction };
}
export function updateTransactionSuccess(transaction) {
  return { type: UPDATE_TRANSACTION_SUCCESS, transaction };
}
export function saveTransactionFailure(error) {
  return { type: SAVE_TRANSACTION_FAILURE, error };
}

export function beginDeleteTransaction() {
  return { type: BEGIN_DELETE_TRANSACTION };
}
export function deleteTransactionSuccess(transaction) {
  return { type: DELETE_TRANSACTION_SUCCESS, transaction };
}
export function deleteTransactionFailure(error) {
  return { type: DELETE_TRANSACTION_FAILURE, error };
}

export function loadTransactions() {
  return async function (dispatch, getState) {
    const token = getState().auth.token;
    dispatch(beginLoadTransactions());
    try {
      const transactions = await transactionApi.getAllTransactions(token);
      dispatch(loadTransactionsSuccess(transactions));
    } catch (error) {
      dispatch(loadTransactionsFailure(error));
      throw error;
    }
  };
}

export function saveTransaction(transaction) {
  //eslint-disable-next-line no-unused-vars
  return async function (dispatch, getState) {
    const token = getState().auth.token;
    dispatch(beginSaveTransaction());
    try {
      const savedTransaction = await transactionApi.saveTransaction(
        transaction,
        token
      );
      transaction.id
        ? dispatch(updateTransactionSuccess(savedTransaction))
        : dispatch(createTransactionSuccess(savedTransaction));
    } catch (error) {
      dispatch(saveTransactionFailure(error));
      throw error;
    }
  };
}

export function deleteTransaction(transaction) {
  return async function (dispatch, getState) {
    const token = getState().auth.token;
    dispatch(beginDeleteTransaction());
    try {
      await transactionApi.deleteTransaction(transaction._id, token);
      return dispatch(deleteTransactionSuccess(transaction));
    } catch (error) {
      dispatch(deleteTransactionFailure(error));
      throw error;
    }
  };
}
