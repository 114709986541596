import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ListItem } from '@material-ui/core';
import TrendingUpTwoToneIcon from '@material-ui/icons/TrendingUpTwoTone';
import TrendingDownTwoToneIcon from '@material-ui/icons/TrendingDownTwoTone';
import CoinIcon from '../../../common/CoinIcon';

const PortfolioBalance = ({ balance, equivalentCurrency }) => {
  /* const handleOnCompleted = useCallback(
    (iconName) =>
     console.log(`${iconName} successfully loaded`),
    []
  ); */

  const handleIconError = useCallback((err) => console.error(err.message), []);

  let trendUpDown;
  if (parseFloat(balance.percentChange) > 0.0) {
    trendUpDown = (
      <div className="font-size-lg text-success">
        <TrendingUpTwoToneIcon />
      </div>
    );
  } else {
    trendUpDown = (
      <div className="font-size-lg text-danger">
        <TrendingDownTwoToneIcon />
      </div>
    );
  }

  return (
    <ListItem className="d-flex justify-content-between align-items-center py-3">
      <div className="d-flex align-items-center mr-4">
        <div
          className="d-flex align-items-center justify-content-center mr-3"
          style={{ height: '40px', width: '40px' }}>
          <CoinIcon
            name={balance.currency.toLowerCase()}
            onError={handleIconError}
          />
        </div>
        <div>
          <div className="font-weight-bold">{balance.currency}</div>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className="text-right mr-3">
          <div className="font-weight-bold font-size-lg">
            {parseFloat(balance.balance).toFixed(3)} {balance.currency}
          </div>
          <div className="font-weight-bold opacity-4">
            {parseFloat(balance.equivalent).toFixed(3)} {equivalentCurrency}
          </div>
        </div>
        {trendUpDown}
      </div>
    </ListItem>
  );
};

PortfolioBalance.propTypes = {
  balance: PropTypes.object
};

export default PortfolioBalance;
