export default {
  auth: {
    isFetching: true,
    isAuthenticated: false,
    token: null,
    user: null,
    message: null
  },
  exchanges: [],
  exchangeVendors: ['Binance', 'Kraken'],
  exchangeApiKeysValid: [],
  exchangeApiStatus: {},
  account: {},
  transactions: {
    summary: [],
    trades: [],
    binance: {
      fiatTransactions: []
    }
  },
  apiCallsInProgress: 0
};
