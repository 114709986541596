import React, { useState } from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';

import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import SaveTwoToneIcon from '@material-ui/icons/SaveTwoTone';

const FilterByMenu = ({ filters, anchorEl, handleClose }) => {
  const [filter1, setFilter1] = useState('');

  const handleFilter1 = (event) => {
    setFilter1(event.target.value);
  };

  const [filter2, setFilter2] = useState('');

  const handleFilter2 = (event) => {
    setFilter2(event.target.value);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      open={Boolean(anchorEl)}
      classes={{ list: 'p-0' }}
      onClose={handleClose}>
      <div className="dropdown-menu-xxl overflow-hidden p-0">
        <div className="p-3">
          <Grid container spacing={6}>
            <Grid item md={6}>
              <FormControl variant="outlined" size="small" className="w-100">
                <InputLabel id="type-select-label">Type</InputLabel>
                <Select
                  labelId="type-select-label"
                  id="type-select-label-id"
                  fullWidth
                  label="Type"
                  value={filter2}
                  onChange={handleFilter2}>
                  <MenuItem value={0}>All types</MenuItem>
                  <MenuItem value={1}>Deposit</MenuItem>
                  <MenuItem value={2}>Buy Crypto</MenuItem>
                  <MenuItem value={3}>Sell Crypto</MenuItem>
                  <MenuItem value={4}>Withdraw</MenuItem>
                  <MenuItem value={5}>Transfer Funds</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" size="small" className="w-100">
                <InputLabel id="status-select-label">Status</InputLabel>
                <Select
                  labelId="status-select-label"
                  id="status-select-label-id"
                  fullWidth
                  label="Status"
                  value={filter1}
                  onChange={handleFilter1}>
                  <MenuItem value={0}>All statuses</MenuItem>
                  <MenuItem value={1}>Pending</MenuItem>
                  <MenuItem value={2}>Completed</MenuItem>
                  <MenuItem value={3}>Rejected</MenuItem>
                  <MenuItem value={4}>Processing</MenuItem>
                  <MenuItem value={5}>Cancelled</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
        <div className="divider" />
        <div className="p-3 text-center bg-secondary">
          <Button className="btn-primary" size="small">
            Filter results
          </Button>
        </div>
        <div className="divider" />
        <div className="p-3">
          <Grid container spacing={6}>
            <Grid item md={6}>
              <List
                component="div"
                className="nav-neutral-danger flex-column p-0">
                <ListItem
                  button
                  className="d-flex rounded-sm justify-content-center"
                  href="#/"
                  onClick={(e) => e.preventDefault()}>
                  <DeleteTwoToneIcon className="mr-2" />
                  <span>Cancel</span>
                </ListItem>
              </List>
            </Grid>
            <Grid item md={6}>
              <List
                component="div"
                className="nav-neutral-success flex-column p-0">
                <ListItem
                  button
                  className="d-flex rounded-sm justify-content-center"
                  href="#/"
                  onClick={(e) => e.preventDefault()}>
                  <SaveTwoToneIcon className="mr-2" />
                  <span>Save filter</span>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </div>
      </div>
    </Menu>
  );
};

export default FilterByMenu;
