import {
  TEST_EXCHANGE_API_KEY_SUCCESS,
  TEST_EXCHANGE_API_KEY_FAILURE,
  CREATE_EXCHANGE_SUCCESS,
  LOAD_EXCHANGES_SUCCESS,
  DELETE_EXCHANGE_SUCCESS
} from '../actions/actionTypes';
import initialState from './initialState';

export default function exchangeApiTestReducer(
  state = initialState.exchangeApiKeysValid,
  action
) {
  switch (action.type) {
    case CREATE_EXCHANGE_SUCCESS:
      return [
        ...state,
        { id: action.exchange.id, isValid: 'loading', error: null }
      ];
    case LOAD_EXCHANGES_SUCCESS:
      return action.exchanges.map((exchange) => ({
        id: exchange.id,
        isValid: 'loading',
        error: null
      }));
    case TEST_EXCHANGE_API_KEY_SUCCESS:
      return state.map((exchangeApiKey) =>
        exchangeApiKey.id === action.id
          ? {
              id: action.id,
              isValid: action.isValid.toString(),
              error: null
            }
          : exchangeApiKey
      );
    case TEST_EXCHANGE_API_KEY_FAILURE:
      return state.map((exchangeApiKey) =>
        exchangeApiKey.id === action.id
          ? {
              id: action.id,
              isValid: 'false',
              error: action.error
            }
          : exchangeApiKey
      );
    case DELETE_EXCHANGE_SUCCESS:
      return state.filter(
        (exchangeApiKey) => exchangeApiKey.id !== action.exchange.id
      );
    default:
      return state;
  }
}
