import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as portfolioActions from '../../../redux/actions/portfolioActions';
import * as excelActions from '../../../redux/actions/excelActions';
import PropTypes from 'prop-types';
import TableHeaderSearch from '../../common/TableActions/TableHeaderSearch';
import PortfolioActions from './PortfolioActions';
import Spinner from '../../common/Spinner';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import PortfolioBalance from './PortfolioBalance';
import SnackbarAlert from '../../common/SnackbarAlert';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    },
    container: {
      maxHeight: 800
    }
  }
}));

const columns = [
  {
    id: 'currency',
    label: 'Currency',
    minWidth: 80,
    align: 'left'
  },
  {
    id: 'balance',
    label: 'Balance',
    minWidth: 80,
    align: 'right',
    format: (value) => value.toLocaleString()
  },
  {
    id: 'bySource',
    label: 'By Source',
    minWidth: 150,
    align: 'left'
  },
  {
    id: 'fiatAmount',
    label: 'in Fiat',
    minWidth: 80,
    align: 'right',
    format: (value) => value.toLocaleString()
  },
  {
    id: 'btcAmount',
    label: 'in BTC',
    minWidth: 80,
    align: 'right',
    format: (value) => value.toLocaleString()
  },
  {
    id: 'fees',
    label: 'Total fees',
    minWidth: 80,
    align: 'right',
    format: (value) => value.toLocaleString()
  },
  {
    id: 'percentChange',
    label: 'Percent Change',
    minWidth: 80,
    align: 'right',
    format: (value) => value.toLocaleString()
  }
];

const PortfolioSummary = ({
  auth,
  portfolio,
  loading,
  downloadFinished,
  success,
  error,
  actions
}) => {
  useEffect(() => {
    if (
      auth.isAuthenticated == true &&
      (portfolio == 'undefined' || portfolio == null)
    ) {
      loadPortfolio();
    }
  }, [auth, portfolio]);

  const loadPortfolio = () => {
    console.log('Loading portfolio..');
    actions.loadPortfolio().catch((error) => {
      console.log('Loading portfolio failed. ' + error);
    });
  };

  // Snackbar handling

  const [snackOpen, setSnackOpen] = React.useState(false);

  const handleSnackOpen = () => {
    setSnackOpen(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackOpen(false);
  };

  // Table Handling
  const classes = useStyles();

  const [globalFilter, setGlobalFilter] = useState(undefined);
  const [preGlobalFilteredRows, setPreGlobalFilteredRows] = useState([]);

  const handleFileDownload = () => {
    // TODO
  };

  useEffect(() => {
    if (downloadFinished == true) {
      handleSnackOpen();
    }
  }, [downloadFinished]);

  const setUserCurrencyLabel = (label) => {
    if (label == 'in Fiat') {
      return `in ${portfolio.equivalentCurrency}`;
    } else return label;
  };

  return (
    <>
      <Card className="mb-5 bg-dark">
        <TableHeaderSearch
          id="transaction-history"
          title="Portfolio Balance Sheet"
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          preGlobalFilteredRows={preGlobalFilteredRows}
        />
        <div className="divider" />
        <div className="divider" />

        <>
          {loading ? (
            <Spinner />
          ) : (
            <>
              {Object.keys(portfolio).length != 0 ? (
                <>
                  {' '}
                  <PortfolioActions
                    handleFileDownload={handleFileDownload}
                    totalEquivalent={portfolio.totalEquivalent}
                    equivalentCurrency={portfolio.equivalentCurrency}
                  />
                  <div className="divider" />
                  <TableContainer className={classes.container}>
                    <Table
                      stickyHeader
                      className="table table-borderless text-nowrap mb-0">
                      <TableHead className="thead-dark text-capitalize font-size-sm font-weight-bold">
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}>
                              {setUserCurrencyLabel(column.label)}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {portfolio.balances.map((balance) => {
                          return (
                            <PortfolioBalance
                              balance={balance}
                              key={balance.currency}
                            />
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>{' '}
                </>
              ) : (
                <p className="text-black-50 m-5">
                  No recent portfolio. Add exchanges or upload transactions
                  first.
                </p>
              )}

              <div className="divider" />
              <div className="card-footer p-4 d-flex justify-content-center"></div>
            </>
          )}
        </>
      </Card>
      <SnackbarAlert
        open={snackOpen}
        handleClose={handleSnackClose}
        error={error}
        success={success}
      />
    </>
  );
};

PortfolioSummary.propTypes = {
  auth: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  transactions: PropTypes.array,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  portfolio: state.portfolio.portfolio,
  loading: state.portfolio.isLoading,
  downloadFinished: state.portfolio.downloadFinished,
  success: state.portfolio.success,
  error: state.portfolio.error
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loadPortfolio: bindActionCreators(
        portfolioActions.loadPortfolio,
        dispatch
      ),
      downloadExcelFile: bindActionCreators(
        excelActions.downloadExcelFile,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioSummary);
