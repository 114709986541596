import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as exchangeApiTestActions from '../../redux/actions/exchangeApiTestActions';
import * as exchangeApiStatusActions from '../../redux/actions/exchangeApiStatusActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '@material-ui/core';
import DialogConfirm from '../common/DialogConfirm';
import TableRow from '@material-ui/core/TableRow';
import StyledTableCell from '../common/styled/StyledTableCell';

import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import CheckIcon from '@material-ui/icons/Check';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';

const Exchange = ({ exchange, onDelete, actions, ...props }) => {
  const [isApiKeyValid, setIsApiKeyValid] = useState(props.isApiKeyValid);
  const [exchangeApiStatus, setExchangeApiStatus] = useState(
    props.exchangeApiStatus
  );
  const [dialogConfirm, setDialogConfirm] = useState(React.createRef());

  useEffect(() => {
    actions.testExchangeApiKey(exchange.id).catch((error) => {
      console.log('Testing exchange Api key failed' + error);
    });
    actions.getExchangeApiStatus(exchange.name).catch((error) => {
      console.log('Error fetching exchange api status' + error);
    });
  }, []);

  useEffect(() => {
    setIsApiKeyValid(props.isApiKeyValid);
  }, [props.isApiKeyValid]);

  useEffect(() => {
    setExchangeApiStatus(props.exchangeApiStatus);
  }, [props.exchangeApiStatus]);

  if (!exchange) return false;

  let isValidIcon;
  if (isApiKeyValid == 'true') {
    isValidIcon = <CheckIcon />;
  } else if (isApiKeyValid == 'false') {
    isValidIcon = <IndeterminateCheckBoxIcon />;
  } else {
    isValidIcon = <HourglassEmptyIcon />;
  }

  let onOffBadge;
  let onOffLabel;
  if (exchangeApiStatus == 'online') {
    onOffBadge = (
      <div className="badge badge-success mr-3 border-0 badge-circle">
        Online
      </div>
    );
    onOffLabel = (
      <div className="font-size-sm opacity-7 text-success d-flex align-items-center">
        Online
      </div>
    );
  } else if (exchangeApiStatus == 'offline') {
    onOffBadge = (
      <div className="badge badge-danger mr-3 border-0 badge-circle">
        Offline
      </div>
    );
    onOffLabel = (
      <div className="font-size-sm opacity-7 text-danger d-flex align-items-center">
        Offline
      </div>
    );
  } else {
    onOffBadge = (
      <div className="badge badge-warning mr-3 border-0 badge-circle">
        Loading
      </div>
    );
    onOffLabel = (
      <div className="font-size-sm opacity-7 text-warning d-flex align-items-center">
        Loading
      </div>
    );
  }

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={exchange._id}>
      <StyledTableCell className="px-4" align="left" key="name">
        <div className="d-flex align-items-center">{exchange.name}</div>
      </StyledTableCell>
      <StyledTableCell key="status">
        <div className="d-flex align-items-center">
          {onOffBadge}
          <div>
            {onOffLabel}
            <div className="font-size-sm opacity-7">
              {new Date().toLocaleString()}
            </div>
          </div>
        </div>
      </StyledTableCell>
      <StyledTableCell align="left" key="api">
        <div>
          <div className="font-size-sm font-weight-bold">{exchange.api}</div>
        </div>
      </StyledTableCell>
      <StyledTableCell key="isValid">
        <div className="font-size-sm font-weight-bold">{isValidIcon}</div>
      </StyledTableCell>
      <StyledTableCell key="actions">
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            dialogConfirm.current.handleClickOpen();
          }}>
          <span className="btn-wrapper--label">Delete</span>
        </Button>
        <DialogConfirm
          ref={dialogConfirm}
          {...onDelete}
          title="Confirm Delete"
          message={
            'Do you really want to delete the ' + exchange.name + ' Exchange?'
          }
          onConfirm={() => onDelete(exchange)}
        />
      </StyledTableCell>
    </TableRow>
  );
};

Exchange.propTypes = {
  exchange: PropTypes.object.isRequired,
  isApiKeyValid: PropTypes.string.isRequired,
  exchangeApiStatus: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  isApiKeyValid: state.exchangeApiKeysValid.find(
    (apiKey) => apiKey.id == ownProps.exchange.id
  ).isValid,
  exchangeApiStatus: state.exchangeApiStatus[ownProps.exchange.name].toString()
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      testExchangeApiKey: bindActionCreators(
        exchangeApiTestActions.testExchangeApiKey,
        dispatch
      ),
      getExchangeApiStatus: bindActionCreators(
        exchangeApiStatusActions.getExchangeApiStatus,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Exchange);
