import {
  BEGIN_TEST_EXCHANGE_API_KEY,
  TEST_EXCHANGE_API_KEY_SUCCESS,
  TEST_EXCHANGE_API_KEY_FAILURE
} from './actionTypes';
import * as exchangeApi from '../../api/exchangeApi';

export function beginTestExchangeApiKey() {
  return { type: BEGIN_TEST_EXCHANGE_API_KEY };
}
export function testExchangeApiKeySuccess(id, isValid) {
  return { type: TEST_EXCHANGE_API_KEY_SUCCESS, id, isValid };
}
export function testExchangeApiKeyFailure(id, error) {
  return { type: TEST_EXCHANGE_API_KEY_FAILURE, id, error };
}

export function testExchangeApiKey(exchangeId) {
  return async function (dispatch, getState) {
    const token = getState().auth.token;
    dispatch(beginTestExchangeApiKey());
    return exchangeApi
      .testExchangeApiKey(exchangeId, token)
      .then((result) => {
        dispatch(testExchangeApiKeySuccess(exchangeId, true));
      })
      .catch((error) => {
        console.log(error);
        if (error.message == 'invalid key')
          dispatch(testExchangeApiKeySuccess(exchangeId, false));
        else dispatch(testExchangeApiKeyFailure(exchangeId, error.message));
        throw error;
      });
  };
}
