import { createHeaders, handleResponse, handleError } from './apiUtils';
const baseUrl = process.env.API_URL + '/portfolio/';

export async function getPortfolio(authToken) {
  var url = new URL(baseUrl);
  //var params = { days, equivalentCurrency };
  //url.search = new URLSearchParams(params).toString();
  return fetch(url, {
    method: 'GET',
    headers: createHeaders(authToken)
  })
    .then(handleResponse)
    .catch(handleError);
}
