import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1)
  },
  selectEmpty: {
    marginTop: theme.spacing(1)
  },
  menu: {
    paper: {
      backgroundColor: '#1c1d3b'
    }
  },
  selectMenu: {
    '& ul': {
      backgroundColor: '#1c1d3b'
    }
  },
  select: {
    '& .MuiSelect-icon': {
      color: '#ffffff'
    },
    '& .MuiSelect-select': {
      color: '#ffffff'
    }
  }
}));

const SelectInput = ({
  name,
  label,
  onChange,
  defaultOption,
  value,
  error,
  options
}) => {
  const classes = useStyles();

  return (
    <div className="form-group">
      <div className="field">
        <FormControl className={classes.formControl}>
          <InputLabel id="simple-select-label" className="text-white">
            {label}
          </InputLabel>
          <Select
            className={classes.select}
            MenuProps={{ classes: { paper: classes.selectMenu } }}
            name={name}
            labelId="simple-select-label"
            id={name}
            value={value}
            onChange={onChange}>
            {options.map((option) => {
              return (
                <MenuItem key={option.value} value={option.value}>
                  {option.text}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {error && <div className="alert alert-danger">{error}</div>}
      </div>
    </div>
  );
};

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultOption: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object)
};

export default SelectInput;
