const newExchange = {
  name: '',
  api: '',
  secret: ''
};

// Using CommonJS style export so we can consume via Node (without using Babel-node)
module.exports = {
  newExchange
};
