import { combineReducers } from 'redux';
import cryptoTransactions from './cryptoTransactionReducer';
import distributions from './distributionReducer';
import fiatPayments from './fiatPaymentReducer';
import fiatTransactions from './fiatTransactionReducer';

export default combineReducers({
  cryptoTransactions,
  distributions,
  fiatTransactions,
  fiatPayments
});
