import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as transactionActions from '../../../../redux/actions/transactionActions';
import PropTypes from 'prop-types';
import TableHeaderSearch from '../../../common/TableActions/TableHeaderSearch';
import TableFilter from '../../../common/TableActions/TableFilter';
import TablePagination from '../../../common/TableActions/TablePagination';
import LedgerEntry from './LedgerEntry';

import Spinner from '../../../common/Spinner';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  container: {
    maxHeight: 440
  }
});

const columns = [
  { id: 'type', label: 'Transaction Type', minWidth: 170 },
  { id: 'source', label: 'Source', minWidth: 80 },
  {
    id: 'asset',
    label: 'Currency',
    minWidth: 50,
    align: 'left'
  },
  {
    id: 'amount',
    label: 'Amount',
    minWidth: 120,
    align: 'right',
    format: (value) => value.toLocaleString('en-US')
  },
  {
    id: 'balance',
    label: 'Balance',
    minWidth: 120,
    align: 'right',
    format: (value) => value.toLocaleString('en-US')
  },
  {
    id: 'id',
    label: 'Transaction ID',
    minWidth: 170,
    align: 'center'
  },
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 80,
    align: 'center'
  }
];

const KrakenLedgers = ({ auth, transactions, loading, actions }) => {
  useEffect(() => {
    if (
      auth.isAuthenticated == true &&
      (transactions == 'undefined' || transactions == null)
    ) {
      loadTransactions();
    }
  }, [auth, transactions]);

  const loadTransactions = () => {
    console.log('Loading kraken ledgers..');
    actions.loadTransactions().catch((error) => {
      console.log('Loading kraken ledgers failed' + error);
    });
  };

  // Table Handling
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [globalFilter, setGlobalFilter] = useState(undefined);
  const [preGlobalFilteredRows, setPreGlobalFilteredRows] = useState([]);
  return (
    <>
      <Card className="mb-5 bg-dark">
        <TableHeaderSearch
          id="kraken-ledger-history"
          title="Kraken Ledger History"
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          preGlobalFilteredRows={preGlobalFilteredRows}
        />
        <div className="divider" />
        <div className="divider" />
        <TableFilter />
        <div className="divider" />
        <>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <TableContainer className={classes.container}>
                <Table
                  stickyHeader
                  className="table table-borderless text-nowrap mb-0">
                  <TableHead className="thead-dark text-capitalize font-size-sm font-weight-bold">
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}>
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {transactions
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((transaction) => {
                        return (
                          <LedgerEntry
                            transaction={transaction}
                            key={transaction._id}
                          />
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>

              <div className="divider" />
              <div className="card-footer p-4 d-flex justify-content-center">
                <TablePagination
                  count={transactions.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </>
          )}
        </>
      </Card>
    </>
  );
};

KrakenLedgers.propTypes = {
  auth: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  transactions: PropTypes.array,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  transactions: state.transactions.kraken.ledgers.transactions,
  loading: state.transactions.kraken.ledgers.isLoading
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loadTransactions: bindActionCreators(
        transactionActions.loadKrakenLedgers,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(KrakenLedgers);
