import React, { useEffect } from 'react';
import * as loginActions from '../../redux/actions/loginActions';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Container } from 'reactstrap';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useOktaAuth } from '@okta/okta-react';

const Home = ({ auth, actions }) => {
  const { authState, oktaAuth } = useOktaAuth();

  useEffect(() => {
    if (!authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      //setAuth({ authenticated: false, token: null, user: null });
    } else {
      const accessToken = oktaAuth.getAccessToken();
      oktaAuth.getUser().then((user) => {
        actions.receiveLogin(user, accessToken);
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  const login = async () => {
    actions.requestLogin();
    oktaAuth.signInWithRedirect();
  };

  if (authState.isPending) {
    return <div>Loading...</div>;
  }

  return (
    <div className="app">
      <Container fluid>
        <h1>Exchange Tracking and Asset Portfolio Manager.</h1>

        {authState.isAuthenticated && !auth.user && (
          <div>Loading user information...</div>
        )}

        {authState.isAuthenticated && auth.user && (
          <div>
            <p>
              Welcome, &nbsp;
              {auth.user.name}!
            </p>
            <Button color="secondary">
              <Link className="app-link" to="/dashboard">
                Dashboard
              </Link>
            </Button>

            <Button color="secondary">
              <Link className="app-link" to="/exchanges">
                Manage Exchanges
              </Link>
            </Button>
          </div>
        )}

        {!authState.isAuthenticated && (
          <div>
            <p>Please log in to manage your crypto.</p>
            <Button color="secondary" onClick={login}>
              Login
            </Button>
            <Button color="secondary" disabled={true}>
              Manage Exchanges
            </Button>
          </div>
        )}
      </Container>
    </div>
  );
};

Home.propTypes = {
  auth: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      requestLogin: bindActionCreators(loginActions.requestLogin, dispatch),
      receiveLogin: bindActionCreators(loginActions.receiveLogin, dispatch),
      deleteExchange: bindActionCreators(loginActions.loginError, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
