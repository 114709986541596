import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PageTitle } from '../../layout-components';
import { Grid } from '@material-ui/core';

import OverviewPortfolio from './OverviewPortfolio';
import OverviewActivity from './OverviewActivity';
import OverviewNews from './OverviewNews';
import OverviewVerification from './OverviewVerification';
import OverviewWatchlist from './OverviewWatchlist';
import OverviewPriceWatch from './OverviewPriceWatch';
import OverviewWallets from './OverviewWallets';
const Overview = ({ auth }) => {
  return (
    <>
      {auth.isAuthenticated && auth.user && (
        <PageTitle
          titleHeading={'Welcome back, ' + auth.user.given_name}
          titleDescription="This page shows an overview for your account summary."></PageTitle>
      )}

      <Grid container spacing={6}>
        <Grid item xl={5} className="d-flex">
          <OverviewPortfolio />
        </Grid>
        <Grid item xl={7}>
          <OverviewPriceWatch />
          <OverviewWallets />
        </Grid>
        <Grid item xl={6}>
          <OverviewActivity />
        </Grid>
        <Grid item xl={6}>
          <OverviewNews />
        </Grid>
      </Grid>
      <OverviewWatchlist />
      <OverviewVerification />
    </>
  );
};

Overview.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps, null)(Overview);
