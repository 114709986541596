import {
  BEGIN_LOAD_PORTFOLIO,
  LOAD_PORTFOLIO_SUCCESS,
  LOAD_PORTFOLIO_FAILURE
} from '../actions/actionTypes';

const initialState = {
  portfolio: null,
  isLoading: true,
  error: null
};

function portfolio(state = initialState, action) {
  switch (action.type) {
    case BEGIN_LOAD_PORTFOLIO:
      return Object.assign({}, state, {
        portfolio: {},
        isLoading: true,
        error: null
      });
    case LOAD_PORTFOLIO_SUCCESS:
      return Object.assign({}, state, {
        portfolio: action.portfolio,
        isLoading: false,
        error: null
      });
    case LOAD_PORTFOLIO_FAILURE:
      return Object.assign({}, state, {
        portfolio: {},
        isLoading: false,
        error: action.error
      });
    default:
      return state;
  }
}

export default portfolio;
