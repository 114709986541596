import React from 'react';
import useDynamicSVGImport from './dynamicSVGImport';

const ExchangeIcon = ({ name, onCompleted, onError, ...rest }) => {
  const { error, loading, SvgIcon } = useDynamicSVGImport('exchange', name, {
    onCompleted,
    onError
  });
  /* if (error) {
    return error.message;
  } */
  if (loading) {
    return 'Loading...';
  }
  if (SvgIcon) {
    return <SvgIcon {...rest} />;
  }
  return null;
};

export default ExchangeIcon;
