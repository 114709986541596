import React from 'react';

import Menu from '@material-ui/core/Menu';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import ArrowDownwardTwoToneIcon from '@material-ui/icons/ArrowDownwardTwoTone';
import ArrowUpwardTwoToneIcon from '@material-ui/icons/ArrowUpwardTwoTone';
import RadioButtonUncheckedTwoToneIcon from '@material-ui/icons/RadioButtonUncheckedTwoTone';

const FilterResultsMenu = ({ anchorEl, handleClose }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={Boolean(anchorEl)}
      classes={{ list: 'p-0' }}
      onClose={handleClose}>
      <div className="dropdown-menu-lg overflow-hidden p-0">
        <div className="font-weight-bold px-4 pt-3">Results</div>
        <List
          component="div"
          className="nav-neutral-first nav-pills-rounded flex-column p-2">
          <ListItem
            component="a"
            button
            href="#/"
            onClick={(e) => e.preventDefault()}>
            <div className="mr-2">
              <RadioButtonUncheckedTwoToneIcon />
            </div>
            <span className="font-size-md">
              <b>10</b> results per page
            </span>
          </ListItem>
          <ListItem
            component="a"
            button
            href="#/"
            onClick={(e) => e.preventDefault()}>
            <div className="mr-2">
              <RadioButtonUncheckedTwoToneIcon />
            </div>
            <span className="font-size-md">
              <b>20</b> results per page
            </span>
          </ListItem>
          <ListItem
            component="a"
            button
            href="#/"
            onClick={(e) => e.preventDefault()}>
            <div className="mr-2">
              <RadioButtonUncheckedTwoToneIcon />
            </div>
            <span className="font-size-md">
              <b>30</b> results per page
            </span>
          </ListItem>
        </List>
        <div className="divider" />
        <div className="font-weight-bold px-4 pt-4">Order</div>
        <List
          component="div"
          className="nav-neutral-first nav-pills-rounded flex-column p-2">
          <ListItem
            component="a"
            button
            href="#/"
            onClick={(e) => e.preventDefault()}>
            <div className="mr-2">
              <ArrowUpwardTwoToneIcon />
            </div>
            <span className="font-size-md">Ascending</span>
          </ListItem>
          <ListItem
            component="a"
            button
            href="#/"
            onClick={(e) => e.preventDefault()}>
            <div className="mr-2">
              <ArrowDownwardTwoToneIcon />
            </div>
            <span className="font-size-md">Descending</span>
          </ListItem>
        </List>
      </div>
    </Menu>
  );
};

export default FilterResultsMenu;
