import React, { useEffect } from 'react';

function Homepage() {
  useEffect(() => {
    window.location.href = 'https://home.cryptoadmin.de';
  }, []);

  return (
    <div>
      <h2>Homepage</h2>
    </div>
  );
}

export default Homepage;
