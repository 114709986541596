import React from 'react';
import { PageTitle } from '../../../layout-components';

import KrakenLedgers from './KrakenLedgers';
import KrakenStaking from './KrakenStaking';

export default function TransactionsKraken() {
  return (
    <>
      <PageTitle
        titleHeading="Kraken Transactions"
        titleDescription="This page shows all your Kraken crypto and fiat deposits, withdrawals and staking rewards.">
        {/* <TransactionsPageTitleActions /> */}
      </PageTitle>
      <KrakenLedgers />
      <KrakenStaking />
    </>
  );
}
