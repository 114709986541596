import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getPriceData } from '../../../redux/actions/priceDataActions';
import { Grid } from '@material-ui/core';
import CoinPriceWatch from './CoinPriceWatch';
import axios from 'axios';

const OverviewPriceWatch = ({ getPriceData }) => {
  const [coins, setCoins] = useState([]);

  useEffect(() => {
    axios
      .get(
        'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=8&page=1&sparkline=false&price_change_percentage=1h%2C24h%2C7d'
      )
      .then((res) => {
        setCoins(res.data);
      })
      .catch((error) => console.error('Error: ' + error));
  }, []);

  return (
    <>
      <Grid container spacing={3} className="mb-4">
        {coins.map((coin) => {
          return <CoinPriceWatch key={coin.id} coin={coin} />;
        })}
      </Grid>
    </>
  );
};

OverviewPriceWatch.propTypes = {
  getPriceData: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  getPriceData
};

export default connect(null, mapDispatchToProps)(OverviewPriceWatch);
