import React, { useState } from 'react';

import UploadMenu from './UploadMenu';
import DownloadMenu from './DownloadMenu';

import Button from '@material-ui/core/Button';

import FilterListTwoToneIcon from '@material-ui/icons/FilterListTwoTone';

import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';

const TransactionsActions = ({
  handleFilterToggle,
  handleFileUpload,
  handleFileDownload
}) => {
  const [anchorUploadMenu, setAnchorUploadMenu] = useState(null);

  const [anchorDownloadMenu, setAnchorDownloadMenu] = useState(null);

  const handleClickToggleFilter = (_event) => {
    handleFilterToggle();
  };

  const handleClickUpload = (event) => {
    setAnchorUploadMenu(event.currentTarget);
  };
  const handleClickDownload = (event) => {
    setAnchorDownloadMenu(event.currentTarget);
  };

  const handleUploadMenuClose = () => {
    setAnchorUploadMenu(null);
  };
  const handleDownloadMenuClose = () => {
    setAnchorDownloadMenu(null);
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between px-4 py-3">
        <div>
          <Button
            onClick={handleClickToggleFilter}
            size="small"
            variant="text"
            className="btn-outline-primary d-40 p-0 d-flex align-items-center justify-content-center mr-2">
            <FilterListTwoToneIcon className="w-50" />
          </Button>
        </div>

        <div className="container d-flex ml-auto justify-content-end">
          <div>
            <Button
              onClick={handleClickDownload}
              size="small"
              variant="text"
              className="btn-outline-primary d-40 p-0  d-flex align-items-center justify-content-center mr-2">
              <GetAppIcon className="w-50" />
            </Button>
            <DownloadMenu
              anchorEl={anchorDownloadMenu}
              handleClose={handleDownloadMenuClose}
              handleFileDownload={handleFileDownload}
            />
          </div>
          <div>
            <Button
              onClick={handleClickUpload}
              size="small"
              variant="text"
              className="btn-outline-primary d-40 p-0  d-flex align-items-center justify-content-center">
              <PublishIcon className="w-50" />
            </Button>
            <UploadMenu
              anchorEl={anchorUploadMenu}
              handleClose={handleUploadMenuClose}
              handleFileUpload={handleFileUpload}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionsActions;
