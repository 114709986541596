import {
  BEGIN_LOAD_KRAKEN_STAKING,
  LOAD_KRAKEN_STAKING_SUCCESS,
  LOAD_KRAKEN_STAKING_FAILURE
} from '../../../actions/actionTypes';

const initialState = {
  transactions: null,
  isLoading: true,
  error: null
};

const stakingTransactions = (state = initialState, action) => {
  switch (action.type) {
    case BEGIN_LOAD_KRAKEN_STAKING:
      return Object.assign({}, state, {
        isLoading: true,
        error: null
      });
    case LOAD_KRAKEN_STAKING_SUCCESS:
      return Object.assign({}, state, {
        transactions: action.items,
        isLoading: false,
        error: null
      });
    case LOAD_KRAKEN_STAKING_FAILURE:
      return Object.assign({}, state, {
        transactions: null,
        isLoading: false,
        error: action.error
      });
    default:
      return state;
  }
};
export default stakingTransactions;
