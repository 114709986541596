import React from 'react';
import { PageTitle } from '../../../layout-components';

import BinanceFiatTransactions from './BinanceFiatTransactions';
import BinanceFiatPayments from './BinanceFiatPayments';
import BinanceCryptoTransactions from './BinanceCryptoTransactions';

import BinanceDistributions from './BinanceDistributions';

export default function TransactionsBinance() {
  return (
    <>
      <PageTitle
        titleHeading="Binance Transactions"
        titleDescription="This page shows all your Binance crypto and fiat deposits, withdrawals and staking rewards.">
        {/* <TransactionsPageTitleActions /> */}
      </PageTitle>
      <BinanceDistributions />
      <BinanceFiatTransactions />
      <BinanceFiatPayments />
      <BinanceCryptoTransactions />
    </>
  );
}
