import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as portfolioActions from '../../../redux/actions/portfolioActions';
import PropTypes from 'prop-types';
import { Card, Button, List, Tooltip } from '@material-ui/core';

import PerfectScrollbar from 'react-perfect-scrollbar';
import PortfolioBalance from './PortfolioBalance';
import { Redirect } from 'react-router-dom';
import Spinner from '../../common/Spinner';
import TotalBalance from '../../Balance/TotalBalance';

const OverviewPortfolio = ({ auth, portfolio, loading, error, actions }) => {
  const [redirectToPortfolio, setRedirectToPortfolio] = useState(false);

  useEffect(() => {
    if (auth.isAuthenticated == true && portfolio == null) loadPortfolio();
  }, [auth, portfolio]);

  const loadPortfolio = () => {
    console.log('Loading portfolio balances..');
    actions.loadPortfolio().catch((error) => {
      console.log('Loading portfolio balances failed' + error);
    });
  };
  return (
    <>
      {redirectToPortfolio && <Redirect to="/portfolio" />}
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Card className="w-100 mb-5 card-dark">
            <div className="card-header d-flex align-items-center justify-content-between card-header-alt p-4">
              <div>
                <h6 className="font-weight-bold font-size-lg mb-1">
                  My Portfolio
                </h6>
                <p className="text-black-50 mb-0">
                  Status of your crypto assets.
                </p>
              </div>
              <div className="d-flex align-items-center">
                <Tooltip title="View stats for last week" arrow placement="top">
                  <Button className="btn-link mx-1 px-1 py-2 font-weight-bold text-primary">
                    <span>1D</span>
                  </Button>
                </Tooltip>
                <Tooltip
                  title="View stats for last month"
                  arrow
                  placement="top">
                  <Button className="btn-link mx-1 px-1 py-2 font-weight-bold text-white opacity-5">
                    <span>1W</span>
                  </Button>
                </Tooltip>
                <Tooltip title="View stats for last year" arrow placement="top">
                  <Button className="btn-link ml-1 px-1 py-2 font-weight-bold text-white opacity-5">
                    <span>1M</span>
                  </Button>
                </Tooltip>
              </div>
            </div>
            <div className="divider" />
            <div className="divider" />

            {Object.keys(portfolio).length != 0 ? (
              <>
                <div className="bg-dark d-flex align-items-center flex-column py-4">
                  <TotalBalance
                    balance={portfolio.totalEquivalent}
                    currency={portfolio.equivalentCurrency}
                  />
                </div>

                <div className="divider" />
                <div className="divider" />
                <div className="scroll-area-xl shadow-overflow card-dark">
                  <PerfectScrollbar>
                    <List component="div" className="list-group-flush">
                      {portfolio.balances.map((balance) => {
                        return (
                          <PortfolioBalance
                            balance={balance}
                            equivalentCurrency={portfolio.equivalentCurrency}
                            key={balance.currency}
                          />
                        );
                      })}
                    </List>
                  </PerfectScrollbar>
                </div>
                <div className="card-footer p-3 text-center">
                  <Button
                    size="small"
                    className="py-2 px-4 btn-primary"
                    onClick={() => setRedirectToPortfolio(true)}>
                    <span className="btn-wrapper--label text-uppercase font-weight-bold">
                      View complete Portfolio
                    </span>
                  </Button>
                </div>
              </>
            ) : (
              <p className="text-black-50 m-5">
                No recent portfolio. Add exchanges or upload transactions first.
              </p>
            )}
          </Card>
        </>
      )}
    </>
  );
};

OverviewPortfolio.propTypes = {
  auth: PropTypes.object.isRequired,
  portfolio: PropTypes.object,
  actions: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    portfolio: state.portfolio.portfolio,
    loading: state.portfolio.isLoading,
    error: state.portfolio.error
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loadPortfolio: bindActionCreators(
        portfolioActions.loadPortfolio,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OverviewPortfolio);
