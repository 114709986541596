import React from 'react';
import Select from 'react-select';
import SelectOption from './SelectOption';

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    borderBottom: '1px dotted pink',
    padding: 0,
    background: '#1c1d3b',
    align: 'left',
    fontSize: 10,
    textTransform: null
  }),
  menuList: (styles) => ({
    ...styles,
    background: '#1c1d3b'
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    background: isFocused ? 'indigo' : isSelected ? 'indigo' : undefined,
    zIndex: 1
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: '#3c44b1',
      margin: 1
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: 'white',
    fontFamily: 'Heebo',
    fontSize: 10
  }),
  control: (base, state) => ({
    ...base,
    background: '#1c1d3b',
    // match with the menu
    borderRadius: state.isFocused ? '3px 3px 0 0' : 3,
    // Overwrittes the different states of border
    borderColor: state.isFocused ? 'white' : '#ffffff00',
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    '&:hover': {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? null : '#3c44b1'
    },
    width: state.selectProps.width,
    padding: 0,
    margin: 1,
    fontFamily: 'Heebo',
    fontSize: 10
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    padding: 1
  }),
  clearIndicator: (styles) => ({
    ...styles,
    width: 10
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
};

export default function MultiSelectFilter({
  column: { filterValue, setFilter, preFilteredRows, id, width }
}) {
  const options = React.useMemo(() => {
    const options = new Map();
    preFilteredRows.forEach((row) => {
      const value = row.values[id];
      if (value != '') {
        const option = new SelectOption(value, value);
        options.set(value, option);
      }
    });
    return [...Array.from(options.values())];
  }, [id, preFilteredRows]);

  return (
    <Select
      defaultValue={filterValue || null}
      isMulti
      name={id}
      options={options}
      styles={customStyles}
      width={width}
      onChange={(e) => {
        setFilter(e ? e.map((data) => data.value) : undefined);
      }}
      components={{ LoadingIndicator: null, ClearIndicator: null }}
    />
  );
}
