import { createHeaders, handleResponse, handleError } from './apiUtils';
const baseUrl = process.env.API_URL + '/transactions/';

export async function getAllTransactions(authToken) {
  return fetch(baseUrl, {
    method: 'GET',
    headers: createHeaders(authToken)
  })
    .then(handleResponse)
    .catch(handleError);
}
export async function saveTransaction(transaction, authToken) {
  return fetch(baseUrl + (transaction.id || ''), {
    method: transaction._id ? 'PUT' : 'POST', // POST for create, PUT to update when id already exists.
    headers: createHeaders(authToken),
    body: JSON.stringify(transaction)
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function deleteTransaction(transactionId, authToken) {
  return fetch(baseUrl + transactionId, {
    method: 'DELETE',
    headers: createHeaders(authToken)
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getBinanceDepositsAndWithdraws(authToken) {
  return fetch(baseUrl + 'binance/depositsAndWithdraws', {
    method: 'GET',
    headers: createHeaders(authToken)
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getBinanceDistributions(authToken) {
  return fetch(baseUrl + 'binance/distributions', {
    method: 'GET',
    headers: createHeaders(authToken)
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getBinanceFiatTransactions(authToken) {
  return fetch(baseUrl + 'binance/fiat/transactions', {
    method: 'GET',
    headers: createHeaders(authToken)
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getBinanceFiatPayments(authToken) {
  return fetch(baseUrl + 'binance/fiat/payments', {
    method: 'GET',
    headers: createHeaders(authToken)
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getKrakenLedgers(authToken) {
  return fetch(baseUrl + 'kraken/ledgers', {
    method: 'GET',
    headers: createHeaders(authToken)
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getKrakenStaking(authToken) {
  return fetch(baseUrl + 'kraken/staking', {
    method: 'GET',
    headers: createHeaders(authToken)
  })
    .then(handleResponse)
    .catch(handleError);
}
