import React, { useState } from 'react';
import { connect } from 'react-redux';
import { saveExchange } from '../../redux/actions/exchangeActions';
import { loadExchangeVendors } from '../../redux/actions/exchangeVendorActions';
import PropTypes from 'prop-types';
import ExchangeForm from './ExchangeForm';
import Spinner from '../common/Spinner';
import { toast } from 'react-toastify';
import { newExchange } from '../../../tools/mockData';

export function ManageExchangePage({
  loadExchangeVendors,
  saveExchange,
  history,
  ...props
}) {
  const [exchangeVendors, setExchangeVendors] = useState(props.exchangeVendors);
  const [exchange, setExchange] = useState({ ...props.exchange });
  const [errors, setErrors] = useState({});
  const [saving, setSaving] = useState(false);

  function handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let newExchange = { ...exchange };
    newExchange[name] = value;
    setExchange(newExchange);
  }

  function formIsValid() {
    const { name, api, secret } = exchange;
    const newErrors = {};
    if (!name) newErrors.name = 'You need to select an exchange.';
    if (!api) newErrors.api = 'Api key is required';
    if (!secret) newErrors.secret = 'Api secret is required';
    setErrors(newErrors);
    // Form is valid if the errors object still has no properties
    return Object.keys(newErrors).length === 0;
  }

  function handleSave(event) {
    event.preventDefault();
    if (!formIsValid()) return;
    setSaving(true);
    console.log(`saving exchange info: ${JSON.stringify(exchange)}`);
    saveExchange(exchange)
      .then(() => {
        toast.success('Exchange saved.');
        history.push('/exchanges');
      })
      .catch((error) => {
        setSaving(false);
        setErrors({ onSave: error.message });
      });
  }

  return (
    <ExchangeForm
      exchange={exchange}
      errors={errors}
      exchangeVendors={exchangeVendors}
      onChange={handleChange}
      onSave={handleSave}
      saving={saving}
    />
  );
}

ManageExchangePage.propTypes = {
  exchange: PropTypes.object,
  exchangeVendors: PropTypes.array.isRequired,
  loadExchangeVendors: PropTypes.func.isRequired,
  saveExchange: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export function getExchangeBySlug(exchanges, slug) {
  return (
    exchanges.find((exchange) => exchange.name.toLowerCase() === slug) || null
  );
}

const mapStateToProps = (state, ownProps) => ({
  exchange: ownProps.exchange ? ownProps.exchange : newExchange,
  exchangeVendors: state.exchangeVendors
});

const mapDispatchToProps = {
  saveExchange
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageExchangePage);
