export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

// Exchanges Page
export const BEGIN_SAVE_EXCHANGE = 'BEGIN_SAVE_EXCHANGE';
export const CREATE_EXCHANGE_SUCCESS = 'CREATE_EXCHANGE_SUCCESS';
export const UPDATE_EXCHANGE_SUCCESS = 'UPDATE_EXCHANGE_SUCCESS';
export const SAVE_EXCHANGE_FAILURE = 'SAVE_EXCHANGE_FAILURE';

export const BEGIN_LOAD_EXCHANGES = 'BEGIN_LOAD_EXCHANGES';
export const LOAD_EXCHANGES_SUCCESS = 'LOAD_EXCHANGES_SUCCESS';
export const LOAD_EXCHANGES_FAILURE = 'LOAD_EXCHANGES_FAILURE';

export const BEGIN_LOAD_EXCHANGE_VENDORS = 'BEGIN_LOAD_EXCHANGE_VENDORS';
export const LOAD_EXCHANGE_VENDORS_SUCCESS = 'LOAD_EXCHANGE_VENDORS_SUCCESS';
export const LOAD_EXCHANGE_VENDORS_FAILURE = 'LOAD_EXCHANGE_VENDORS_FAILURE';

export const BEGIN_DELETE_EXCHANGE = 'BEGIN_DELETE_EXCHANGE';
export const DELETE_EXCHANGE_SUCCESS = 'DELETE_EXCHANGE_SUCCESS';
export const DELETE_EXCHANGE_FAILURE = 'DELETE_EXCHANGE_FAILURE';

export const BEGIN_TEST_EXCHANGE_API_KEY = 'BEGIN_TEST_EXCHANGE_API_KEY';
export const TEST_EXCHANGE_API_KEY_SUCCESS = 'TEST_EXCHANGE_API_KEY_SUCCESS';
export const TEST_EXCHANGE_API_KEY_FAILURE = 'TEST_EXCHANGE_API_KEY_FAILURE';

export const BEGIN_GET_EXCHANGE_STATUS = 'BEGIN_GET_EXCHANGE_STATUS';
export const GET_EXCHANGE_STATUS_SUCCESS = 'GET_EXCHANGE_STATUS_SUCCESS';
export const GET_EXCHANGE_STATUS_FAILURE = 'GET_EXCHANGE_STATUS_FAILURE';

// Portfolio Balances
export const BEGIN_LOAD_PORTFOLIO = 'BEGIN_LOAD_PORTFOLIO';
export const LOAD_PORTFOLIO_SUCCESS = 'LOAD_PORTFOLIO_SUCCESS';
export const LOAD_PORTFOLIO_FAILURE = 'LOAD_PORTFOLIO_FAILURE';

export const BEGIN_LOAD_ACCOUNT_BALANCES = 'BEGIN_LOAD_ACCOUNT_BALANCES';
export const LOAD_ACCOUNT_BALANCES_SUCCESS = 'LOAD_ACCOUNT_BALANCES_SUCCESS';
export const LOAD_ACCOUNT_BALANCES_FAILURE = 'LOAD_ACCOUNT_BALANCES_FAILURE';

// Transactions Page
export const BEGIN_LOAD_TRANSACTIONS = 'BEGIN_LOAD_TRANSACTIONS';
export const LOAD_TRANSACTIONS_SUCCESS = 'LOAD_TRANSACTIONS_SUCCESS';
export const LOAD_TRANSACTIONS_FAILURE = 'LOAD_TRANSACTIONS_FAILURE';

export const BEGIN_SAVE_TRANSACTION = 'BEGIN_SAVE_TRANSACTION';
export const CREATE_TRANSACTION_SUCCESS = 'CREATE_TRANSACTION_SUCCESS';
export const UPDATE_TRANSACTION_SUCCESS = 'UPDATE_TRANSACTION_SUCCESS';
export const SAVE_TRANSACTION_FAILURE = 'SAVE_TRANSACTION_FAILURE';

export const BEGIN_DELETE_TRANSACTION = 'BEGIN_DELETE_TRANSACTION';
export const DELETE_TRANSACTION_SUCCESS = 'DELETE_TRANSACTION_SUCCESS';
export const DELETE_TRANSACTION_FAILURE = 'DELETE_TRANSACTION_FAILURE';

export const BEGIN_LOAD_TRADES = 'BEGIN_LOAD_TRADES';
export const LOAD_TRADES_SUCCESS = 'LOAD_TRADES_SUCCESS';
export const LOAD_TRADES_FAILURE = 'LOAD_TRADES_FAILURE';

export const BEGIN_SAVE_TRADE = 'BEGIN_SAVE_TRADE';
export const CREATE_TRADE_SUCCESS = 'CREATE_TRADE_SUCCESS';
export const UPDATE_TRADE_SUCCESS = 'UPDATE_TRADE_SUCCESS';
export const SAVE_TRADE_FAILURE = 'SAVE_TRADE_FAILURE';

export const BEGIN_DELETE_TRADE = 'BEGIN_DELETE_TRADE';
export const DELETE_TRADE_SUCCESS = 'DELETE_TRADE_SUCCESS';
export const DELETE_TRADE_FAILURE = 'DELETE_TRADE_FAILURE';

export const BEGIN_LOAD_BINANCE_CRYPTO_TRANSACTIONS =
  'BEGIN_LOAD_BINANCE_CRYPTO_TRANSACTIONS';
export const LOAD_BINANCE_CRYPTO_TRANSACTIONS_SUCCESS =
  'LOAD_BINANCE_CRYPTO_TRANSACTIONS_SUCCESS';
export const LOAD_BINANCE_CRYPTO_TRANSACTIONS_FAILURE =
  'LOAD_BINANCE_CRYPTO_TRANSACTIONS_FAILURE';

export const BEGIN_LOAD_BINANCE_DISTRIBUTIONS =
  'BEGIN_LOAD_BINANCE_DISTRIBUTIONS';
export const LOAD_BINANCE_DISTRIBUTIONS_SUCCESS =
  'LOAD_BINANCE_DISTRIBUTIONS_SUCCESS';
export const LOAD_BINANCE_DISTRIBUTIONS_FAILURE =
  'LOAD_BINANCE_DISTRIBUTIONS_FAILURE';

export const BEGIN_LOAD_BINANCE_FIAT_TRANSACTIONS =
  'BEGIN_LOAD_BINANCE_FIAT_TRANSACTIONS';
export const LOAD_BINANCE_FIAT_TRANSACTIONS_SUCCESS =
  'LOAD_BINANCE_FIAT_TRANSACTIONS_SUCCESS';
export const LOAD_BINANCE_FIAT_TRANSACTIONS_FAILURE =
  'LOAD_BINANCE_FIAT_TRANSACTIONS_FAILURE';

export const BEGIN_LOAD_BINANCE_FIAT_PAYMENTS =
  'BEGIN_LOAD_BINANCE_FIAT_PAYMENTS';
export const LOAD_BINANCE_FIAT_PAYMENTS_SUCCESS =
  'LOAD_BINANCE_FIAT_PAYMENTS_SUCCESS';
export const LOAD_BINANCE_FIAT_PAYMENTS_FAILURE =
  'LOAD_BINANCE_FIAT_PAYMENTS_FAILURE';

export const BEGIN_LOAD_KRAKEN_LEDGERS = 'BEGIN_LOAD_KRAKEN_LEDGERS';
export const LOAD_KRAKEN_LEDGERS_SUCCESS = 'LOAD_KRAKEN_LEDGERS_SUCCESS';
export const LOAD_KRAKEN_LEDGERS_FAILURE = 'LOAD_KRAKEN_LEDGERS_FAILURE';

export const BEGIN_LOAD_KRAKEN_STAKING = 'BEGIN_LOAD_KRAKEN_STAKING';
export const LOAD_KRAKEN_STAKING_SUCCESS = 'LOAD_KRAKEN_STAKING_SUCCESS';
export const LOAD_KRAKEN_STAKING_FAILURE = 'LOAD_KRAKEN_STAKING_FAILURE';

// Price Data
export const BEGIN_GET_PRICE_DATA = 'BEGIN_GET_PRICE_DATA';
export const GET_PRICE_DATA_SUCCESS = 'GET_PRICE_DATA_SUCCESS';
export const GET_PRICE_DATA_FAILURE = 'GET_PRICE_DATA_FAILURE';

// Excel file upload
export const BEGIN_UPLOAD_EXCEL_FILE = 'BEGIN_UPLOAD_EXCEL_FILE';
export const UPLOAD_EXCEL_FILE_SUCCESS = 'UPLOAD_EXCEL_FILE_SUCCESS';
export const UPLOAD_EXCEL_FILE_FAILURE = 'UPLOAD_EXCEL_FILE_FAILURE';

// Excel file download
export const BEGIN_DOWNLOAD_EXCEL_FILE = 'BEGIN_DOWNLOAD_EXCEL_FILE';
export const DOWNLOAD_EXCEL_FILE_SUCCESS = 'DOWNLOAD_EXCEL_FILE_SUCCESS';
export const DOWNLOAD_EXCEL_FILE_FAILURE = 'DOWNLOAD_EXCEL_FILE_FAILURE';
