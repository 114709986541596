import {
  BEGIN_GET_EXCHANGE_STATUS,
  GET_EXCHANGE_STATUS_SUCCESS,
  GET_EXCHANGE_STATUS_FAILURE
} from './actionTypes';
import * as exchangeApi from '../../api/exchangeApi';

export function beginGetExchangeApiStatus() {
  return { type: BEGIN_GET_EXCHANGE_STATUS };
}
export function getExchangeApiStatusSuccess(name, status) {
  return { type: GET_EXCHANGE_STATUS_SUCCESS, name, status };
}
export function getExchangeApiStatusFailure(error) {
  return { type: GET_EXCHANGE_STATUS_FAILURE, error };
}

export function getExchangeApiStatus(name) {
  return async function (dispatch, getState) {
    const token = getState().auth.token;
    dispatch(beginGetExchangeApiStatus());
    return exchangeApi
      .getExchangeApiStatus(name, token)
      .then((status) => {
        dispatch(getExchangeApiStatusSuccess(name, status));
      })
      .catch((error) => {
        dispatch(getExchangeApiStatusFailure(error));
        throw error;
      });
  };
}
