import { combineReducers } from 'redux';
import exchanges from './exchangeReducer';
import exchangeVendors from './exchangeVendorReducer';
import exchangeApiKeysValid from './exchangeApiTestReducer';
import exchangeApiStatus from './exchangeApiStatusReducer';
import portfolio from './portfolioReducer';
import transactions from './transactions';
import auth from './authReducer';
import themeOptions from './themeOptions';

const rootReducer = combineReducers({
  auth,
  exchanges,
  exchangeVendors,
  exchangeApiKeysValid,
  exchangeApiStatus,
  portfolio,
  transactions,
  themeOptions
});

export default rootReducer;
