import React, { useState } from 'react';

import FilterByMenu from './FilterByMenu';
import FilterResultsMenu from './FilterResultsMenu';

import Button from '@material-ui/core/Button';

import FilterListTwoToneIcon from '@material-ui/icons/FilterListTwoTone';

import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';

const TableFilter = ({ filters }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const [anchorEl2, setAnchorEl2] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  return (
    <>
      <div className="d-flex align-items-center justify-content-between px-4 py-3">
        <div>
          <Button
            onClick={handleClick}
            size="small"
            variant="text"
            className="btn-outline-primary d-40 p-0 d-flex align-items-center justify-content-center mr-2">
            <FilterListTwoToneIcon className="w-50" />
          </Button>
          <FilterByMenu
            filters={filters}
            anchorEl={anchorEl}
            handleClose={handleClose}
          />
        </div>
        <div>
          <Button
            onClick={handleClick2}
            size="small"
            variant="text"
            className="btn-outline-primary d-40 p-0  d-flex align-items-center justify-content-center">
            <SettingsTwoToneIcon className="w-50" />
          </Button>
          <FilterResultsMenu anchorEl={anchorEl2} handleClose={handleClose2} />
        </div>
      </div>
    </>
  );
};

export default TableFilter;
