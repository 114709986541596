import {
  BEGIN_LOAD_EXCHANGES,
  LOAD_EXCHANGES_SUCCESS,
  LOAD_EXCHANGES_FAILURE,
  BEGIN_SAVE_EXCHANGE,
  CREATE_EXCHANGE_SUCCESS,
  UPDATE_EXCHANGE_SUCCESS,
  SAVE_EXCHANGE_FAILURE,
  BEGIN_DELETE_EXCHANGE,
  DELETE_EXCHANGE_SUCCESS,
  DELETE_EXCHANGE_FAILURE
} from '../actions/actionTypes';

const initialState = {
  exchangeList: null,
  isLoading: true,
  error: null
};

const exchanges = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_EXCHANGE_SUCCESS:
      return Object.assign({}, state, {
        exchangeList: [...state.exchangeList, { ...action.exchange }]
      });
    case UPDATE_EXCHANGE_SUCCESS:
      return Object.assign({}, state, {
        exchangeList: state.exchangeList.map((exchange) =>
          exchange.id === action.exchange.id ? action.exchange : exchange
        )
      });
    case BEGIN_LOAD_EXCHANGES:
      return Object.assign({}, state, {
        isLoading: true,
        error: null
      });
    case LOAD_EXCHANGES_SUCCESS:
      return Object.assign({}, state, {
        exchangeList: action.exchanges,
        isLoading: false,
        error: null
      });
    case LOAD_EXCHANGES_FAILURE:
      return Object.assign({}, state, {
        exchangeList: [],
        isLoading: false,
        error: action.error
      });
    case DELETE_EXCHANGE_SUCCESS:
      return Object.assign({}, state, {
        exchangeList: state.exchangeList.filter(
          (exchange) => exchange.id !== action.exchange.id
        ),
        error: null
      });
    default:
      return state;
  }
};
export default exchanges;
