import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function exchangeVendorReducer(
  state = initialState.exchangeVendors,
  action
) {
  switch (action.type) {
    case types.LOAD_EXCHANGE_VENDORS_SUCCESS:
      return action.exchangeVendors;
    default:
      return state;
  }
}
