import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as transactionSummaryActions from '../../../redux/actions/transactionSummaryActions';
import * as excelActions from '../../../redux/actions/excelActions';
import PropTypes from 'prop-types';
import TableHeaderSearch from '../../common/TableActions/TableHeaderSearch';
import TransactionsActions from './TransactionsActions';
import TablePagination from '../../common/TableActions/TablePagination';
import SelectFilter from '../../common/TableActions/TableFilter/SelectFilter';
import Spinner from '../../common/Spinner';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Table from '../../common/Table';
import TableContainer from '@material-ui/core/TableContainer';
import DateCell from '../../common/Table/CustomCell/DateCell';
import SnackbarAlert from '../../common/SnackbarAlert';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    },
    container: {
      maxHeight: 800
    }
  }
}));

const TransactionsSummary = ({
  auth,
  transactions,
  loading,
  uploadFinished,
  downloadFinished,
  success,
  error,
  actions
}) => {
  useEffect(() => {
    if (
      auth.isAuthenticated == true &&
      (transactions == 'undefined' || transactions == null)
    ) {
      loadTransactions();
    }
  }, [auth, transactions]);

  const loadTransactions = () => {
    console.log('Loading transactions..');
    actions.loadTransactions().catch((error) => {
      console.log('Loading transactions failed. ' + error);
    });
  };

  // Snackbar handling
  const [snackOpen, setSnackOpen] = React.useState(false);

  const handleSnackOpen = () => {
    setSnackOpen(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackOpen(false);
  };

  // Table Handling
  const columns = React.useMemo(
    () => [
      {
        Header: 'Time',
        accessor: 'time',
        disableFilters: true,
        Cell: function DateCellFunction(cell) {
          return <DateCell value={cell.value} />;
        },
        width: 150
      },
      {
        Header: 'Type',
        accessor: 'type',
        minWidth: 170
      },
      {
        Header: 'Source',
        accessor: 'source',
        minWidth: 90
      },
      {
        Header: 'Buy',
        align: 'center',
        columns: [
          {
            id: 'buyAmount',
            Header: 'Amount',
            accessor: (row) => {
              return Number(row.buyAmount).toLocaleString('fullwide', {
                maximumFractionDigits: 5
              });
            },
            disableFilters: true,
            align: 'right',
            width: 80
          },
          {
            Header: 'Currency',
            accessor: 'buyCurrency',
            width: 125
          }
        ]
      },
      {
        Header: 'Sell',
        align: 'center',
        columns: [
          {
            id: 'sellAmount',
            Header: 'Amount',
            accessor: (row) => {
              return Number(row.sellAmount).toLocaleString('fullwide', {
                maximumFractionDigits: 5
              });
            },
            disableFilters: true,
            align: 'right',
            width: 80
          },
          {
            Header: 'Currency',
            accessor: 'sellCurrency',
            width: 125
          }
        ]
      },
      {
        Header: 'Fee',
        align: 'center',
        columns: [
          {
            id: 'feeAmount',
            Header: 'Amount',
            accessor: (row) => {
              return Number(row.feeAmount).toLocaleString('fullwide', {
                maximumFractionDigits: 5
              });
            },
            disableFilters: true,
            align: 'right',
            width: 80
          },
          {
            Header: 'Currency',
            accessor: 'feeCurrency',
            width: 125
          }
        ]
      },
      {
        Header: 'Sub Type',
        accessor: 'subType',
        minWidth: 120
      },
      {
        Header: 'Comment',
        accessor: 'comment',
        minWidth: 120
      }
    ],
    []
  );
  const classes = useStyles();
  const [pageIndex, setPageIndex] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const handleChangePage = (event, newPage) => {
    setPageIndex(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPageIndex(0);
  };

  const [page, setPage] = useState([]);
  const handlePageUpdate = (page) => {
    setPage(page);
  };

  const [globalFilter, setGlobalFilter] = useState(undefined);
  const [preGlobalFilteredRows, setPreGlobalFilteredRows] = useState([]);
  const [filterRowToggle, setFilterRowToggle] = useState(false);

  const handleFilterToggle = () => {
    setFilterRowToggle(!filterRowToggle);
  };

  // Data handling
  const handleFileUpload = (file) => {
    console.log('Uploading Excel file..');
    actions.uploadExcelFile(file).catch((error) => {
      console.log('File upload failed. ' + error);
    });
  };

  const handleFileDownload = () => {
    console.log('Downloading Excel file..');
    actions.downloadExcelFile().catch((error) => {
      console.log('File download failed. ' + error);
    });
  };

  useEffect(() => {
    if (uploadFinished == true) {
      handleSnackOpen();
      if (error == null) loadTransactions();
    }
  }, [uploadFinished]);

  useEffect(() => {
    if (downloadFinished == true) {
      handleSnackOpen();
    }
  }, [downloadFinished]);

  return (
    <>
      <Card className="mb-5 bg-dark">
        {!loading && (
          <TableHeaderSearch
            id="transaction-history"
            title="All Transactions"
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            preGlobalFilteredRows={preGlobalFilteredRows}
          />
        )}

        <div className="divider" />
        <div className="divider" />
        <TransactionsActions
          handleFilterToggle={handleFilterToggle}
          handleFileUpload={handleFileUpload}
          handleFileDownload={handleFileDownload}
        />
        <div className="divider" />
        <>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <TableContainer className={classes.container}>
                <CssBaseline />
                <Table
                  columns={columns}
                  data={transactions}
                  pageIndex={pageIndex}
                  pageSize={rowsPerPage}
                  globalFilter={globalFilter}
                  setPreGlobalFilteredRows={setPreGlobalFilteredRows}
                  setInitialGlobalfilter={setGlobalFilter}
                  filterToggle={filterRowToggle}
                />
              </TableContainer>

              <div className="divider" />
              <div className="card-footer p-4 d-flex justify-content-center">
                <TablePagination
                  count={preGlobalFilteredRows.length}
                  rowsPerPage={rowsPerPage}
                  page={pageIndex}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </>
          )}
        </>
      </Card>
      <SnackbarAlert
        open={snackOpen}
        handleClose={handleSnackClose}
        error={error}
        success={success}
      />
    </>
  );
};

TransactionsSummary.propTypes = {
  auth: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  transactions: PropTypes.array,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  transactions: state.transactions.summary.transactionList,
  loading: state.transactions.summary.isLoading,
  uploadFinished: state.transactions.summary.uploadFinished,
  downloadFinished: state.transactions.summary.downloadFinished,
  success: state.transactions.summary.success,
  error: state.transactions.summary.error
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loadTransactions: bindActionCreators(
        transactionSummaryActions.loadTransactions,
        dispatch
      ),
      uploadExcelFile: bindActionCreators(
        excelActions.uploadExcelFile,
        dispatch
      ),
      downloadExcelFile: bindActionCreators(
        excelActions.downloadExcelFile,
        dispatch
      )
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionsSummary);
