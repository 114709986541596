import { withStyles } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';

const StyledTablePagination = withStyles({
  root: {
    color: 'white'
  },
  selectRoot: {
    selectMenu: {
      paper: {
        backgroundColor: '#1c1d3b'
      }
    }
  },
  selectIcon: {
    color: 'white'
  }
})(TablePagination);

export default StyledTablePagination;
