import React, { useState } from 'react';

import FilterByMenu from '../../../common/TableActions/TableFilter/FilterByMenu';
import DownloadMenu from './DownloadMenu';

import Button from '@material-ui/core/Button';
import TotalBalance from '../../../Balance/TotalBalance';

import FilterListTwoToneIcon from '@material-ui/icons/FilterListTwoTone';

import GetAppIcon from '@material-ui/icons/GetApp';

const PortfolioActions = ({
  filters,
  handleFileDownload,
  totalEquivalent,
  equivalentCurrency
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-center px-4 py-3">
        <div className="bg-dark d-flex align-items-center flex-column py-4 ml-auto">
          <TotalBalance
            balance={totalEquivalent}
            currency={equivalentCurrency}
          />
        </div>
        <div className="ml-auto">
          <Button
            onClick={handleClick}
            size="small"
            variant="text"
            className="btn-outline-primary d-40 p-0  d-flex align-items-center justify-content-center mr-2">
            <GetAppIcon className="w-50" />
          </Button>
          <DownloadMenu
            anchorEl={anchorEl}
            handleClose={handleClose}
            handleFileDownload={handleFileDownload}
          />
        </div>
      </div>
    </>
  );
};

export default PortfolioActions;
