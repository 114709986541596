import { createHeaders, handleResponse, handleError } from './apiUtils';
const baseUrl = process.env.API_URL + '/price/';

export async function getPriceData(base, quote, days, authToken) {
  var url = baseUrl;
  var params = { base, quote, days };
  url.search = new URLSearchParams(params).toString();
  return fetch(url, {
    method: 'GET',
    headers: createHeaders(authToken)
  })
    .then(handleResponse)
    .catch(handleError);
}
