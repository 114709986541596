import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Card, Table } from '@material-ui/core';

const CoinPriceWatch = ({ coin }) => {
  return (
    <Grid item md={3}>
      <Card className="mb-3 p-4 text-center bg-dark">
        <h6 className="d-flex align-items-center align-self-center font-weight-bold font-size-xl mb-3 text-white">
          <img alt={coin.name} src={coin.image} className="coinImage" />
          <div>
            #{coin.market_cap_rank} {coin.name}
          </div>
        </h6>
        <div className="display-4 text-white mb-3 font-weight-bold">
          <small className="opacity-8">$</small>
          {coin.current_price}
        </div>
        <Table className="mt-5">
          <thead>
            <tr>
              <th>1h:</th>
              <th>24h:</th>
              <th>7d:</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {coin.price_change_percentage_1h_in_currency < 0 ? (
                <td className="red">
                  <b>
                    {coin.price_change_percentage_1h_in_currency.toFixed(2)}%
                  </b>
                </td>
              ) : (
                <td className="green">
                  <b>
                    +{coin.price_change_percentage_1h_in_currency.toFixed(2)}%
                  </b>
                </td>
              )}
              {coin.price_change_percentage_24h_in_currency < 0 ? (
                <td className="red">
                  <b>
                    {coin.price_change_percentage_24h_in_currency.toFixed(2)}%
                  </b>
                </td>
              ) : (
                <td className="green">
                  <b>
                    +{coin.price_change_percentage_24h_in_currency.toFixed(2)}%
                  </b>
                </td>
              )}
              {coin.price_change_percentage_7d_in_currency < 0 ? (
                <td className="red">
                  <b>
                    {coin.price_change_percentage_7d_in_currency.toFixed(2)}%
                  </b>
                </td>
              ) : (
                <td className="green">
                  <b>
                    +{coin.price_change_percentage_7d_in_currency.toFixed(2)}%
                  </b>
                </td>
              )}
            </tr>
          </tbody>
        </Table>
      </Card>
    </Grid>
  );
};

CoinPriceWatch.propTypes = {
  coin: PropTypes.object.isRequired
};

export default CoinPriceWatch;
