import React, { useEffect, useState } from 'react';

import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import StyledTableCell from '../styled/StyledTableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MultiSelectFilter from '../TableActions/TableFilter/MultiSelectFilter';

import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useFlexLayout
} from 'react-table';

const Table = ({
  columns,
  data,
  pageIndex,
  pageSize,
  globalFilter,
  setInitialGlobalfilter,
  setPreGlobalFilteredRows,
  filterToggle
}) => {
  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      }
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: MultiSelectFilter,
      filter: 'includesSome'
    }),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    setPageSize,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes
    },
    useFlexLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    gotoPage(pageIndex);
  }, [pageIndex]);

  useEffect(() => {
    setPageSize(pageSize);
  }, [pageSize]);

  useEffect(() => {
    setPreGlobalFilteredRows(preGlobalFilteredRows);
  }, [preGlobalFilteredRows]);

  useEffect(() => {
    setInitialGlobalfilter(state.globalFilter);
  }, [state.globalFilter]);

  useEffect(() => {
    setGlobalFilter(globalFilter);
  }, [globalFilter]);

  const [filterRowToggle, setFilterRowToggle] = useState(filterToggle);

  useEffect(() => {
    setFilterRowToggle(filterToggle);
  }, [filterToggle]);

  return (
    <MaUTable
      {...getTableProps()}
      className="table table-borderless text-nowrap mb-0">
      <TableHead className="thead-dark font-size-sm">
        {headerGroups.map((headerGroup) => {
          const headerGroupProps = headerGroup.getHeaderGroupProps();
          return (
            <TableRow {...headerGroupProps} key={headerGroupProps.key}>
              {headerGroup.headers.map((column) => {
                const headerProps = column.getHeaderProps();
                return (
                  <TableCell
                    {...headerProps}
                    align={column.align}
                    key={headerProps.key}>
                    <span
                      {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ArrowDropDownIcon />
                        ) : (
                          <ArrowDropUpIcon />
                        )
                      ) : null}
                    </span>
                    {filterRowToggle && (
                      <div>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableHead>
      <TableBody>
        {page.map((row) => {
          prepareRow(row);
          const rowProps = row.getRowProps();
          return (
            <TableRow {...rowProps} key={rowProps.key}>
              {row.cells.map((cell) => {
                const cellProps = cell.getCellProps();
                return (
                  <StyledTableCell
                    {...cellProps}
                    align={cell.column.align}
                    key={cellProps.key}>
                    {cell.render('Cell')}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </MaUTable>
  );
};

export default Table;
