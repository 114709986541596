import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function exchangeApiStatusReducer(
  state = initialState.exchangeApiStatus,
  action
) {
  let exchangesWithStatus = Object.assign({}, state);
  switch (action.type) {
    case types.LOAD_EXCHANGES_SUCCESS:
      action.exchanges.map((exchange) => {
        exchangesWithStatus[exchange.name] = 'loading';
      });
      return Object.assign({}, exchangesWithStatus);
    case types.GET_EXCHANGE_STATUS_SUCCESS:
      exchangesWithStatus[action.name] = action.status['status'];
      return Object.assign({}, exchangesWithStatus);
    default:
      return state;
  }
}
