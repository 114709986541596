const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

export async function handleResponse(response) {
  if (response.ok) return response.json();
  return response.json().then((response) => {
    throw new Error(response.message);
  });
}

export async function handleFileResponse(response) {
  if (response.ok) return response.blob();
  return response.json().then((response) => {
    throw new Error(response.message);
  });
}

export function handleError(error) {
  // eslint-disable-next-line no-console
  console.error('API call failed. ' + error);
  throw error;
}

export function createHeaders(authToken) {
  return authToken
    ? {
        ...headers,
        Authorization: 'Bearer ' + authToken
      }
    : headers;
}

export function createHeadersFileUpload(authToken) {
  return {
    Authorization: 'Bearer ' + authToken
  };
}
