import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import StyledTablePagination from '../../styled/StyledTablePagination';

const useStyles = makeStyles({
  selectMenu: {
    '& ul': {
      backgroundColor: '#1c1d3b'
    }
  }
});

const TablePagination = ({
  count,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange
}) => {
  const classes = useStyles();

  return (
    <StyledTablePagination
      SelectProps={{
        MenuProps: { classes: { paper: classes.selectMenu } }
      }}
      rowsPerPageOptions={[10, 15, 20, 25, 50, 100]}
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
    />
  );
};

export default TablePagination;
