import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { setSidebarToggleMobile } from '../../redux/reducers/themeOptions';

import {
  SidebarHeader,
  SidebarMenu,
  SidebarFooter
} from '../../layout-components';

const Sidebar = (props) => {
  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  const {
    sidebarStyle,
    sidebarShadow,
    sidebarFooter,
    sidebarToggleMobile,
    setSidebarToggleMobile
  } = props;
  return (
    <>
      <div
        className={clsx('app-sidebar', sidebarStyle, {
          'app-sidebar--shadow': sidebarShadow
        })}>
        <SidebarHeader />
        <div className="app-sidebar--content">
          <SidebarMenu />
        </div>
        {sidebarFooter && <SidebarFooter />}
      </div>
      <div
        onClick={toggleSidebarMobile}
        className={clsx('app-sidebar-overlay', {
          'is-active': sidebarToggleMobile
        })}
      />
    </>
  );
};
Sidebar.propTypes = {
  sidebarStyle: PropTypes.string.isRequired,
  sidebarShadow: PropTypes.bool.isRequired,
  sidebarFooter: PropTypes.bool.isRequired,
  sidebarToggleMobile: PropTypes.bool.isRequired,
  setSidebarToggleMobile: PropTypes.func.isRequired
};
const mapStateToProps = (state) => ({
  sidebarShadow: state.themeOptions.sidebarShadow,
  sidebarFooter: state.themeOptions.sidebarFooter,
  sidebarStyle: state.themeOptions.sidebarStyle,
  sidebarToggleMobile: state.themeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
