import React, { lazy, Suspense, useState, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { SecureRoute, LoginCallback, useOktaAuth } from '@okta/okta-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as loginActions from './redux/actions/loginActions';
import { bindActionCreators } from 'redux';
import { AnimatePresence, motion } from 'framer-motion';
import { ClimbingBoxLoader } from 'react-spinners';

import { ThemeProvider } from '@material-ui/styles';

import MuiTheme from './theme';

// Layout Blueprints

import {
  LeftSidebar,
  MinimalLayout,
  PresentationLayout
} from './layout-blueprints';

// Pages
import Home from './components/home/Home';
import AboutPage from './components/about/AboutPage';

import Overview from './components/Overview';
import ExchangesPage from './components/exchanges/ExchangesPage';
import ManageExchangePage from './components/exchanges/ManageExchangePage';
import Portfolio from './components/Portfolio';
import Transactions from './components/Transactions';
import TransactionsBinance from './components/Transactions/TransactionsBinance';
import TransactionsKraken from './components/Transactions/TransactionsKraken';
import Profile from './components/Profile';
import Settings from './components/Settings';
import PageLoginCover from './components/PageLoginCover';
import PageRegisterCover from './components/PageRegisterCover';
import PageRecoverCover from './components/PageRecoverCover';
import PageError404 from './components/PageError404';
import Homepage from './components/homepage/Homepage';

const Routes = ({ auth, actions }) => {
  const { authState, oktaAuth } = useOktaAuth();
  useEffect(() => {
    if (!authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      //setAuth({ authenticated: false, token: null, user: null });
    } else {
      const accessToken = oktaAuth.getAccessToken();
      oktaAuth.getUser().then((user) => {
        actions.receiveLogin(user, accessToken);
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'linear',
    duration: 0.3
  };

  const login = async () => {
    actions.requestLogin();
    oktaAuth.signInWithRedirect();
  };

  const SuspenseLoading = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
      let timeout = setTimeout(() => setShow(true), 300);
      return () => {
        clearTimeout(timeout);
      };
    }, []);

    return (
      <>
        <AnimatePresence>
          {show && (
            <motion.div
              key="loading"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}>
              <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
                <div className="d-flex align-items-center flex-column px-4">
                  <ClimbingBoxLoader color={'#3c44b1'} loading={true} />
                </div>
                <div className="text-muted font-size-xl text-center pt-3">
                  Please wait while the App is Loading..
                  <span className="font-size-lg d-block text-dark">
                    This preview instance can be slower than a real production
                    build!
                  </span>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    );
  };
  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            <Redirect exact from="/" to="/overview" />

            <Route path={['/home']}>
              <PresentationLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path="/home" component={Home} />
                  </motion.div>
                </Switch>
              </PresentationLayout>
            </Route>

            <Route path="/login/callback" component={LoginCallback} />

            <Route
              path={[
                '/overview',
                '/portfolio',
                '/transactions',
                '/transactions-binance',
                '/transactions-kraken',
                '/exchanges',
                '/add-exchange',
                '/profile',
                '/settings'
              ]}>
              <LeftSidebar>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <SecureRoute path="/overview" component={Overview} />
                    <SecureRoute path="/portfolio" component={Portfolio} />

                    <SecureRoute path="/exchanges" component={ExchangesPage} />
                    <SecureRoute
                      path="/add-exchange"
                      render={(props) => <ManageExchangePage {...props} />}
                    />
                    <SecureRoute
                      path="/transactions"
                      component={Transactions}
                    />
                    <SecureRoute
                      path="/transactions-binance"
                      component={TransactionsBinance}
                    />
                    <SecureRoute
                      path="/transactions-kraken"
                      component={TransactionsKraken}
                    />
                    <SecureRoute path="/profile" component={Profile} />
                    <SecureRoute path="/settings" component={Settings} />
                  </motion.div>
                </Switch>
              </LeftSidebar>
            </Route>

            <Route
              path={['/login', '/homepage', '/register', '/recover', '/404']}>
              <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path="/login" component={PageLoginCover} />
                    <Route path="/homepage" component={Homepage} />
                    <Route path="/register" component={PageRegisterCover} />
                    <Route path="/recover" component={PageRecoverCover} />
                    <Route path="/404" component={PageError404} />
                  </motion.div>
                </Switch>
              </MinimalLayout>
            </Route>
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

Routes.propTypes = {
  auth: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      requestLogin: bindActionCreators(loginActions.requestLogin, dispatch),
      receiveLogin: bindActionCreators(loginActions.receiveLogin, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
