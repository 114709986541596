import {
  BEGIN_LOAD_EXCHANGE_VENDORS,
  LOAD_EXCHANGE_VENDORS_SUCCESS,
  LOAD_EXCHANGE_VENDORS_FAILURE
} from './actionTypes';
import * as exchangeApi from '../../api/exchangeApi';

export function beginLoadExchangeVendors() {
  return { type: BEGIN_LOAD_EXCHANGE_VENDORS };
}
export function loadExchangeVendorsSuccess(exchangeVendors) {
  return { type: LOAD_EXCHANGE_VENDORS_SUCCESS, exchangeVendors };
}
export function loadExchangeVendorsFailure(error) {
  return { type: LOAD_EXCHANGE_VENDORS_FAILURE, error };
}

export function loadExchangeVendors() {
  return async function (dispatch, getState) {
    dispatch(beginLoadExchangeVendors());
    const token = getState().auth.token;
    try {
      const exchangeVendors = await exchangeApi.getSupportedExchanges(token);
      dispatch(loadExchangeVendorsSuccess(exchangeVendors));
    } catch (error) {
      dispatch(loadExchangeVendorsFailure(error));
      throw error;
    }
  };
}
