import React from 'react';

import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';

const DownloadMenu = ({ anchorEl, handleClose, handleFileDownload }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={Boolean(anchorEl)}
      classes={{ list: 'p-0' }}
      onClose={handleClose}>
      <div className="dropdown-menu-lg overflow-hidden p-0">
        <div className="font-weight-bold px-4 pt-4">Download transactions</div>
        <div className="p-3 text-center">
          <Button
            className="btn-primary"
            size="small"
            onClick={handleFileDownload}>
            Download
          </Button>
        </div>
      </div>
    </Menu>
  );
};

export default DownloadMenu;
