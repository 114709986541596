import {
  BEGIN_LOAD_PORTFOLIO,
  LOAD_PORTFOLIO_SUCCESS,
  LOAD_PORTFOLIO_FAILURE
} from './actionTypes';
import * as portfolioApi from '../../api/portfolioApi';

export function beginLoadPortfolio() {
  return { type: BEGIN_LOAD_PORTFOLIO };
}
export function loadPortfolioSuccess(portfolio) {
  return { type: LOAD_PORTFOLIO_SUCCESS, portfolio };
}
export function loadPortfolioFailure(error) {
  return { type: LOAD_PORTFOLIO_FAILURE, error };
}

export function loadPortfolio() {
  return async function (dispatch, getState) {
    const token = getState().auth.token;
    dispatch(beginLoadPortfolio());
    try {
      const portfolio = await portfolioApi.getPortfolio(token);
      console.log(`portfolio balance: ${portfolio.totalEquivalentBTC}`);
      dispatch(loadPortfolioSuccess(portfolio));
    } catch (error) {
      dispatch(loadPortfolioFailure(error.message));
      throw error;
    }
  };
}
