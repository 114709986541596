import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import StyledTableCell from '../../../../common/styled/StyledTableCell';

const Distribution = ({ transaction }) => {
  let tansactionTypeBadge = (
    <div className="badge badge-success mr-3 border-0 badge-circle">
      {transaction.enInfo}
    </div>
  );
  let tansactionTypeLabel = (
    <div className="font-size-sm opacity-7 text-success d-flex align-items-center">
      {transaction.enInfo}
    </div>
  );

  return (
    <TableRow hover role="checkbox" tabIndex={-1}>
      <StyledTableCell className="px-4" align="left" key="type">
        <div className="d-flex align-items-center">
          {tansactionTypeBadge}
          <div>
            {tansactionTypeLabel}
            <div className="font-size-sm opacity-7">
              {new Date(transaction.time).toLocaleString()}
            </div>
          </div>
        </div>
      </StyledTableCell>
      <StyledTableCell align="left" key="source">
        <div>
          <div className="font-size-sm font-weight-bold">Binance</div>
          <div className="font-size-sm opacity-7">Distributions</div>
        </div>
      </StyledTableCell>
      <StyledTableCell align="left" key="asset">
        <div>
          <div className="font-size-sm font-weight-bold">
            {transaction.asset}
          </div>
        </div>
      </StyledTableCell>
      <StyledTableCell align="right" key="amount">
        <div className="font-size-sm font-weight-bold">
          {transaction.amount} {transaction.asset}
        </div>
        {/* <div className="font-size-sm opacity-7">
          {trade.quoteQuantity} {baseQuoteCurrency[1]}
        </div> */}
      </StyledTableCell>
      <StyledTableCell align="center" key="id">
        <div>
          <div className="font-size-sm font-weight-bold">{transaction._id}</div>
        </div>
      </StyledTableCell>
      <StyledTableCell align="center" key="actions">
        <Button className="btn-neutral-primary mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
          <FontAwesomeIcon icon={['fas', 'search']} className="font-size-sm" />
        </Button>
        <Button className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
          <FontAwesomeIcon icon={['far', 'edit']} className="font-size-sm" />
        </Button>
        <Button className="btn-neutral-danger mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
          <FontAwesomeIcon icon={['fas', 'times']} className="font-size-sm" />
        </Button>
      </StyledTableCell>
    </TableRow>
  );
};

export default Distribution;
